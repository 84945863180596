<template>
  <a-spin :spinning="spinning">
    <section>
      <div class="modal-header">
        <div class="modal-title">
          <a-icon type="unordered-list" />项目协议管理
        </div>
      </div>
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :actions-type="actionsType"
        :is-full="false"
        :hide-row-selection="true"
        :hide-pagination="true"
      >
      </ele-table>
      <div class="modal-footer">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ l("关闭") }}
        </a-button>
        <a-button :loading="saving" :type="'primary'" @click="addPreTemplete">
          <a-icon type="plus" />
          {{ l("添加") }}
        </a-button>
      </div>
    </section>

    <a-modal
      centered
      :title="'添加前置协议'"
      :visible="preAgreementVisible"
      :confirm-loading="confirmLoading"
      :cancelText="'关闭'"
      :okText="'保存'"
      :maskClosable="true"
      :destroyOnClose="true"
      :width="500"
      @ok="savePreProAgreement"
      @cancel="closePreProAgreement"
    >
      <div>
        <a-form :form="modalForm" v-bind="formItemLayout">
          <a-form-item label="前置协议" has-feedback>
            <a-select
              mode="multiple"
              style="width: 100%"
              placeholder="请选择前置协议"
              v-decorator="[
                'preProAgreementIds',
                {
                  rules: [
                    { required: true, message: '请选择前置协议' },
                  ],
                },
              ]"
            >
              <a-select-option v-for="item in projectAgreementData" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <span slot="label">
              协议关系&nbsp;
              <a-tooltip title="并且：配置的前置协议需要全部完成签署；或者：配置的前置协议其中一份或多份完成签署即可">
                <a-icon type="question-circle-o" style="color:red;" />
              </a-tooltip>
            </span>
            <a-select
              style="width: 100%"
              placeholder="请选择协议关联关系"
              v-decorator="[
                'correlateType',
                {
                  rules: [
                    { required: true, message: '请选择协议关联关系' },
                  ],
                },
              ]"
            >
              <a-select-option :value="1">并且</a-select-option>
              <a-select-option :value="2">或者</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import EleTable from "@/components/ele-table";
import ConfigList from "./config-list";
import ConfigListOrg from "./config-list-org";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  name: "templete-list",
  mixins: [ModalComponentBase],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      preAgreementVisible: false,
      confirmLoading: false,
      commonService: null,
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      modalForm: this.$form.createForm(this, { name: "coordinated" }),
      tableData: [],
      actionsType: {},
      projectAgreementData:[],
      // 用户表格
      columns: [
        {
          title: this.l("协议模板名称"),
          dataIndex: "templateName",
          align: "center",
          scopedSlots: { customRender: "templateName" },
        },
        {
          title: this.l("前置协议名称"),
          dataIndex: "preTemplateName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "preTemplateName" },
        },
        {
          title: this.l("是否作废"),
          dataIndex: "isInvalid",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "isInvalid" },
        },
        {
          title: this.l("协议关系"),
          dataIndex: "correlateType",
          sorter: false,
          align: "center",
          type:'custom',
          scopedSlots: { customRender: "correlateType" },
          customRender: (text, record, index) => {
            if (text === 'And') {
              return '并且';
            }
            return '或者';
          }
        },
        {
          title: this.l("创建时间"),
          dataIndex: "creationTime",
          align: "center",
          scopedSlots: { customRender: "creationTime" },
          type:'custom',
          customRender: (text, record, index) => {
            if (text && text !== '') {
              return text.replace('T', ' ');
            }
            return '';
          }
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.fullData(); // 模态框必须,填充数据到data字段
  },
  mounted() {
    if (this.params) {
      this.$nextTick(() => {});
    }
    this.initActionsType();
    this.getProjectPreAgreements();
  },
  methods: {
    async getProjectPreAgreements() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/GetProjectPreAgreements",
          params: { projectId: this.projectId, projectAgreementId: this.agreementId },
        });
        this.tableData = res.map((item) => {
          return {
            ...item
          };
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "templete-list",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("删除"),
              icon: "close-circle",
              fn: (data) => {
                _this.deletePreAgreement(data.id);
              },
            }
          ],
        },
      };
      this.actionsType = obj;
    },
    async deletePreAgreement (id) {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/DeletePreProjectAgreements",
          params: { ids:[id] },
        });
        this.spinning = false;
        if(res) {
          this.$message.success("删除成功");
          this.getProjectPreAgreements();
        }
      } catch (error) {
        console.error(error);
        this.spinning = false;
      }
    },
    addPreTemplete() {
      this.getProjectAgreements();
    },
    getProjectAgreements() {
      this.spinning = true;
      const curProjectAgreementId = this.agreementId;
      this.commonService.get({
        url: "/api/services/app/UnnaturalPersonProjectAgreement/GetListByProjectId",
        params: { projectId: this.projectId },
      }).then(res => {
        this.spinning = false;
        this.projectAgreementData.splice(0, this.projectAgreementData.length);
        if(res) {
          let data = res.filter(t => t.id !== curProjectAgreementId);
          if (data && data.length > 0) {
            this.projectAgreementData = data.map(it => it);
          }
        }
        this.preAgreementVisible = true;
      }).catch(e => {
        this.spinning = false;
        console.error(e);
      });
    },
    closePreProAgreement () {
      this.preAgreementVisible = false;
    },
    savePreProAgreement () {
      const _this = this;
      this.modalForm.validateFields(async (err, values) => {
        if (!err) {
          _this.confirmLoading = true;
          try {
            let _res = await _this.commonService.post({
              url: "/api/services/app/UnnaturalPersonProjectAgreement/AddPreProjectAgreements",
              params: {
                projectId: _this.projectId,
                projectAgreementId: _this.agreementId,
                preProjectAgreementIds: values.preProAgreementIds,
                correlateType: values.correlateType
              },
            });
            if(_res) {
              this.$message.success("保存成功");
              _this.confirmLoading = false;
              _this.preAgreementVisible = false;
              _this.getProjectPreAgreements();
            }
          } catch (error) {
            _this.confirmLoading = false;
          }
        }
      });
    }
  },
};
</script>

<style scoped lang="less">
@import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
</style>
