<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="id">{{ l("Edit") }}</span>
        <span v-if="!id">{{ l("Create") }}</span>
      </div>
    </div>
    <a-form
      :form="form"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      @submit="handleSubmit"
    >
      <a-form-item label="协议模版" has-feedback>
        <a-select
          v-decorator="[
            'agreementTemplateId',
            { rules: [{ required: true, message: '请选择类型' }] },
          ]"
          placeholder="请选择"
          @change="_isAgreementSigningMethods"
          show-search
          :filter-option="filterOption"
        >
          <a-select-option
            v-for="(item, index) in selectList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <div class="btn-w">
      <a-button type="button" @click="close()">
        {{ l("Cancel") }}
      </a-button>
      <a-button type="primary" html-type="submit" @click="handleSubmit">
        {{ l("Save") }}
      </a-button>
    </div>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import { message } from "ant-design-vue";
import moment from "moment";
export default {
  mixins: [ModalComponentBase],
  name: "add-or-edit-project-scene-agreement",
  data() {
    return {
      // 获取到的数据
      entity: {},
      spinning: false,
      commonService: null,
      labelCol: {
        span: 5,
      },
      selectList: [],
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  async mounted() {
    // if (this.id) {
    //   await this.getData();
    // }
    this._agreementTemplateGetSelectData();
  },
  methods: {
    /**
     * 获取数据
     */
    // async getData() {
    //   this.spinning = true;
    //   var res = await this.commonService.get({
    //     url: "/api/services/app/UnnaturalPersonProjectScene/GetProjectSceneDetail",
    //     params: { id: this.id },
    //   });
    //   this.spinning = false;
    //   this.$nextTick(() => {
    //     this.form.setFieldsValue({
    //       ...res,
    //       id: this.id,
    //     });
    //   });
    // },
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        console.log(err);
        console.log(values);
        if (!err) {
          this.spinning = true;
          try {
            const res = await this.commonService.post({
              url: "/api/services/app/UnnaturalPersonProjectScene/CreateProjectSceneAgreement",
              params: {
                // ...values,
                unnaturalPersonProjectSceneId: this.id,
                unnaturalPersonProjectAgreementId:values.agreementTemplateId
              },
            });
            this.spinning = false;
            this.$notification["success"]({
              message: this.l("SavedSuccessfully"),
            });
            this.success(true);
          } catch (error) {
            this.spinning = false;
            // this.$message.error("提交失败");
          }
        }
      });
    },
    async _agreementTemplateGetSelectData() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectScene/GetProjectAgreementSelectListByProjectId",
          params: { projectId: this.unnaturalPersonProjectId }, //非自然人项目Id
        });
        this.spinning = false;
        this.selectList = res;
      } catch (error) {
        this.spinning = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container .ant-form-item-children {
  display: block;
  margin: 10px auto;
  text-align: center;
}

.btn-w {
  width: 100%;
  display: block;
  margin: 10px auto;
  text-align: right;
}
</style>