var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('section',[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[_c('a-icon',{attrs:{"type":"unordered-list"}}),_vm._v("项目协议管理 ")],1)]),_c('ele-table',{attrs:{"columns":_vm.columns,"table-data":_vm.tableData,"actions-type":_vm.actionsType,"is-full":false,"hide-row-selection":true,"hide-pagination":true}}),_c('div',{staticClass:"modal-footer"},[_c('a-button',{attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('a-icon',{attrs:{"type":"close-circle"}}),_vm._v(" "+_vm._s(_vm.l("关闭"))+" ")],1),_c('a-button',{attrs:{"loading":_vm.saving,"type":'primary'},on:{"click":_vm.addPreTemplete}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.l("添加"))+" ")],1)],1)],1),_c('a-modal',{attrs:{"centered":"","title":'添加前置协议',"visible":_vm.preAgreementVisible,"confirm-loading":_vm.confirmLoading,"cancelText":'关闭',"okText":'保存',"maskClosable":true,"destroyOnClose":true,"width":500},on:{"ok":_vm.savePreProAgreement,"cancel":_vm.closePreProAgreement}},[_c('div',[_c('a-form',_vm._b({attrs:{"form":_vm.modalForm}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',{attrs:{"label":"前置协议","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'preProAgreementIds',
              {
                rules: [
                  { required: true, message: '请选择前置协议' } ],
              } ]),expression:"[\n              'preProAgreementIds',\n              {\n                rules: [\n                  { required: true, message: '请选择前置协议' },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"multiple","placeholder":"请选择前置协议"}},_vm._l((_vm.projectAgreementData),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayout,false),[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" 协议关系  "),_c('a-tooltip',{attrs:{"title":"并且：配置的前置协议需要全部完成签署；或者：配置的前置协议其中一份或多份完成签署即可"}},[_c('a-icon',{staticStyle:{"color":"red"},attrs:{"type":"question-circle-o"}})],1)],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'correlateType',
              {
                rules: [
                  { required: true, message: '请选择协议关联关系' } ],
              } ]),expression:"[\n              'correlateType',\n              {\n                rules: [\n                  { required: true, message: '请选择协议关联关系' },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择协议关联关系"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v("并且")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v("或者")])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }