<template>
  <div>
    <div class="modal-header">
      <div class="modal-title" style="width: 100%; text-align: left">
        <span>{{ title }}</span>
      </div>
    </div>
    <a-form-model
      layout="inline"
      :model="formInline"
      @submit="handleSubmit"
      @submit.native.prevent
    >
      <a-form-model-item>
        <a-input
          v-model="formInline.keyword"
          :placeholder="placeholder"
          allowClear
        >
          <a-icon slot="prefix" type="key" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-button html-type="submit">
          <a-icon type="search" /> <span>搜索</span>
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button
          type="primary"
          @click="selectHandler"
          :disabled="selectedRowKeys.length == 0"
        >
          <span>确定选择</span>
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <!-- :customRow="customRowHandler" -->
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :loading="spinning"
      :pagination="pagination"
      @change="handleTableChange"
      size="small"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: selectType,
      }"
    >
    </a-table>
  </div>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import ReactTable from "@/components/react-table";
import { message } from "ant-design-vue";
const orginColumns = [
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    slots: { title: "customTitle" },
    scopedSlots: { customRender: "name" },
  },
  //   {
  //     title: "ID",
  //     dataIndex: "id",
  //     key: "id",
  //   },
  {
    title: "描述",
    dataIndex: "description",
    key: "description",
  },
];

export default {
  mixins: [ModalComponentBase],
  components: { ReactTable },
  name: "select-page-list",
  data() {
    return {
      placeholder: "请输入关键字...",
      formInline: {
        keyword: "",
      },
      pagination: {
        pageSize: 10,
        current: 1,
      },
      spinning: false,
      data: [],
      columns: [],
      title: "请选择",
      selectType: "radio",
      selectedRows: [],
      selectedRowKeys: [],
      url: "",
    };
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    if (this.url === "") {
      message.info("请配置URL地址.");
      return;
    }
    if (this.searchPlaceholder) {
      this.placeholder = this.searchPlaceholder;
    }
    if (!this.columnsData || !this.columnsData.length) {
      this.columns = orginColumns;
    } else {
      this.columns = this.columnsData;
    }
    this.fetch({
      pageSize: this.pagination.pageSize,
      current: this.pagination.current,
      keyword: this.formInline.keyword,
    });
  },
  methods: {
    onSelectChange(keys, rows) {
      this.selectedRowKeys = keys;
      this.selectedRows = rows;
    },
    customRowHandler(record, index) {
      return {
        props: {
          //    xxx... // 属性
        },
        on: {
          // 事件
          click: (event) => {
            const rowKeyIndex = this.selectedRowKeys.findIndex(
              (key) => key == record.key
            );
            if (this.selectType === "checkbox") {
              // 如果未选择
              if (rowKeyIndex === -1) {
                this.selectedRowKeys.push(record.key);
                this.selectedRows.push(record);
              } else {
                this.selectedRows.splice(rowKeyIndex, 1);
                this.selectedRowKeys.splice(rowKeyIndex, 1);
              }
            } else {
              // 如果未选择
              if (rowKeyIndex === -1) {
                //先清空
                this.selectedRowKeys = [];
                this.selectedRows = [];
                this.selectedRowKeys.push(record.key);
                this.selectedRows.push(record);
              }
            }
            // message.info(this.id);
          }, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {}, // 鼠标移入行
          mouseleave: (event) => {},
        },
      };
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        pageSize: pagination.pageSize,
        current: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        keyword: this.formInline.keyword,
      });
    },
    selectHandler() {
      if (this.selectType === "checkbox") {
        this.success({ success: true, data: this.selectedRows });
      } else {
        this.success({ success: true, data: this.selectedRows[0] });
      }
    },
    handleSubmit() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.fetch({
        pageSize: this.pagination.pageSize,
        current: 1,
        keyword: this.formInline.keyword,
      });
    },
    fetch(params = {}) {
      const { pageSize, current, keyword } = params;
      const guid = this.id || "";
      const skipCount = (current - 1) * pageSize;
      this.spinning = true;
      let url = `${this.$apiUrl}${this.url}${this.url.indexOf('?') >= 0 ? '&' : '?'}maxResultCount=${pageSize}&skipCount=${skipCount}&keyword=${keyword}&id=${guid}`;
      if (this.otherParams && this.otherParams.length) {
        this.otherParams.map((item) => {
          url = url + `&${item.key}=${item.value}`;
        });
      }
      this.$api
        .request({
          // url: `${this.$apiUrl}${this.url}?maxResultCount=${pageSize}&skipCount=${skipCount}&keyword=${keyword}&id=${guid}`,
          url: url,
          method: "GET",
        })
        .then((res) => {
          if (res.status === 200) {
            const pagination = { ...this.pagination };
            const { data } = res;
            pagination.total = data.totalCount;
            this.pagination = pagination;
            this.data = data.items;
          }
        })
        .catch((e) => {
          //   abp.message.error(this.l("Error500Desc"));
          console.error(e);
          //   message.error(e.message);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
  },
};
</script>