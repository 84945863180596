<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>({{ data.name }})采集结果</span>
      </div>
    </div>
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :form="form">
      <a-row :gutter="24">
        <a-col :span="24" v-for="(item, index) in data.results" :key="index">
          <a-form-item
            :label="item.value.label"
            has-feedback
            v-if="!item.value.isFile"
          >
            <a-input readonly :value="item.value.value" disabled="false" />
          </a-form-item>
          <a-form-item :label="item.value.label" has-feedback v-else>
            <div v-for="(it, i) in item.value.value" :key="i">
              <a class="_link"
                target="_blank" 
                @click="preview(it)"
              >
                <a-icon :type="it.fileType === 'img' ? 'picture' : 'file'" />
                <span style="margin-left: 5px;">{{it.oUrl}}</span>
              </a>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import { CommonServiceProxy } from "@/shared/common-service";
export default {
  mixins: [ModalComponentBase],
  name: "collection-form-result",
  data() {
    return {
      // 获取到的数据
      entity: {},
      spinning: false,
      _serviceProxy: "",
      labelCol: {
        span: 5,
      },
      // 上传文件
      fileList: [],
      uploadUrl: "",
      subdirName: "",
      isDisabled: false,
      commonService: null,
      data: {},
      previewVisible: false,
      previewImage: "",
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.fullData(); // 模态框必须,填充数据到data字段
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  async mounted() {
    if (this.id) {
      await this.getData();
    }
  },
  methods: {
    /**
     * 获取数据
     */
    async getData() {
      let _that = this;
      _that.spinning = true;
      try {
        let res = await _that.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectForm/GetFormResultById",
          params: {
            resultId: _that.id,
          },
        });
        // let arr = [];
        if (res.results != null && res.results.length > 0) {
          res.results.forEach((item) => {
            // 重写处理图片
            if (item.value.value && item.value.value.indexOf('/static/') !== -1) {
              let _value = item.value.value
              let imgArr
              if (_value.indexOf(',') !== -1) {
                let _arr = _value.split(',');
                imgArr = _arr.map(it => {
                  return {
                    oUrl: it,
                    url: `${AppConsts.uploadBaseUrl}${it}`,
                    fileType: this.isImage(it.split('.')[1]) ? 'img' : 'otherFile',
                  }
                })
              } else {
                imgArr = [{url: `${AppConsts.uploadBaseUrl}${_value}`, fileType: this.isImage(_value.split('.')[1])}]
              }
              item.value.value = imgArr;
              item.value.isFile = true;
            } else if (item.value.value && (item.value.value.toLowerCase() === "true" || item.value.value.toLowerCase() === "false")) {
              item.value.value = item.value.value ? "是" : "否";
            }
          });
        }
        _that.data = res;
        console.log(_that.data);
        _that.spinning = false;
      } catch (error) {
        _that.spinning = false;
      }
    },
    isImage(ext) {
      return (
        [
          "png",
          "jpg",
          "jpeg",
          "bmp",
          "gif",
          "webp",
          "psd",
          "svg",
          "tiff",
        ].indexOf(ext.toLowerCase()) !== -1
      );
    },
    // 关闭模态框
    handleCancel() {
      this.previewVisible = false;
    },
    preview(item) {
      if (item.fileType === 'img') {
        this.handlePreview(item.url);
      } else {
        window.open(item.url);
      }
    },
    // 打开模态框（图片预览）
    async handlePreview(file) {
      this.previewImage = file;
      this.previewVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container {
  display: block;
  margin: 10px auto;
  text-align: center;
}
._img {
  width: 100px;
  margin-left: 10px;
}
._img:first-of-type{
  margin-left: 0;
}
</style>