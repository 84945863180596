var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('section',[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[_c('a-icon',{attrs:{"type":"unordered-list"}}),_vm._v("项目协议管理 ")],1)]),_c('ele-table',{attrs:{"columns":_vm.columns,"table-data":_vm.tableData,"actions-type":_vm.actionsType,"is-full":false,"hide-row-selection":true,"hide-pagination":true}}),_c('div',{staticClass:"modal-footer"},[_c('a-button',{attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('a-icon',{attrs:{"type":"close-circle"}}),_vm._v(" "+_vm._s(_vm.l("关闭"))+" ")],1),_c('a-button',{attrs:{"loading":_vm.saving,"type":'primary'},on:{"click":_vm.addTemplete}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.l("添加"))+" ")],1)],1)],1),_c('a-modal',{attrs:{"centered":"","title":_vm.seclectModalParams.title,"visible":_vm.seclectModalParams.visible,"confirm-loading":_vm.seclectModalParams.confirmLoading,"cancelText":_vm.seclectModalParams.cancelText,"okText":_vm.seclectModalParams.okText,"ok-button-props":_vm.seclectModalParams.confirmDisabled,"maskClosable":_vm.seclectModalParams.maskClosable,"destroyOnClose":_vm.seclectModalParams.destroyOnClose,"width":_vm.seclectModalParams.width},on:{"ok":_vm.seclectModalParams.confirm,"cancel":_vm.seclectModalParams.cancel}},[_c('div',[_c('a-form',_vm._b({attrs:{"form":_vm.form1}},'a-form',_vm.formItemLayout,false),[(_vm.seclectModalParams.title === '选择协议模版')?_c('a-form-item',{attrs:{"label":"协议模版","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'agreementTemplateId',
              { rules: [{ required: true, message: '请选择类型' }] } ]),expression:"[\n              'agreementTemplateId',\n              { rules: [{ required: true, message: '请选择类型' }] },\n            ]"}],attrs:{"placeholder":"请选择","show-search":"","filter-option":_vm.filterOption},on:{"change":_vm._isAgreementSigningMethods}},_vm._l((_vm.seclectModalParams.selectList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"签署方式","required":""}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['isOrderSign']),expression:"['isOrderSign']"}],attrs:{"default-value":"0"}},[_c('a-radio',{attrs:{"value":"0"}},[_vm._v(" 默认签署 ")]),_c('a-radio',{attrs:{"value":"1"}},[_vm._v(" 指定签署 ")])],1)],1)],1)],1)]),_c('a-modal',{attrs:{"centered":"","title":_vm.inputModalParams.title,"visible":_vm.inputModalParams.visible,"confirm-loading":_vm.inputModalParams.confirmLoading,"cancelText":_vm.inputModalParams.cancelText,"okText":_vm.inputModalParams.okText,"maskClosable":_vm.inputModalParams.maskClosable,"destroyOnClose":_vm.inputModalParams.destroyOnClose,"width":_vm.inputModalParams.width},on:{"ok":_vm.inputModalParams.confirm,"cancel":_vm.inputModalParams.cancel}},[_c('div',[_c('a-form',_vm._b({attrs:{"form":_vm.form2}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',{attrs:{"label":"作废原因","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'invalidReason',
              {
                rules: [
                  { required: true, message: this.l('ThisFieldIsRequired') } ],
              } ]),expression:"[\n              'invalidReason',\n              {\n                rules: [\n                  { required: true, message: this.l('ThisFieldIsRequired') },\n                ],\n              },\n            ]"}],attrs:{"type":"textarea","placeholder":_vm.l('请输入')}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }