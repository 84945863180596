<template>
  <a-spin :spinning="spinning">
    <section>
      <div class="modal-header">
        <div class="modal-title">
          <a-icon type="unordered-list" />项目协议管理
        </div>
      </div>
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :actions-type="actionsType"
        :is-full="false"
        :hide-row-selection="true"
        :hide-pagination="true"
      >
      </ele-table>
      <div class="modal-footer">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ l("关闭") }}
        </a-button>
        <a-button :loading="saving" :type="'primary'" @click="addTemplete">
          <a-icon type="plus" />
          {{ l("添加") }}
        </a-button>
      </div>
    </section>

    <a-modal
      centered
      :title="seclectModalParams.title"
      :visible="seclectModalParams.visible"
      :confirm-loading="seclectModalParams.confirmLoading"
      :cancelText="seclectModalParams.cancelText"
      :okText="seclectModalParams.okText"
      :ok-button-props="seclectModalParams.confirmDisabled"
      :maskClosable="seclectModalParams.maskClosable"
      :destroyOnClose="seclectModalParams.destroyOnClose"
      :width="seclectModalParams.width"
      @ok="seclectModalParams.confirm"
      @cancel="seclectModalParams.cancel"
    >
      <div>
        <a-form :form="form1" v-bind="formItemLayout">
          <a-form-item
            label="协议模版"
            has-feedback
            v-if="seclectModalParams.title === '选择协议模版'"
          >
            <a-select
              v-decorator="[
                'agreementTemplateId',
                { rules: [{ required: true, message: '请选择类型' }] },
              ]"
              placeholder="请选择"
              @change="_isAgreementSigningMethods"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="(item, index) in seclectModalParams.selectList"
                :key="index"
                :value="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="签署方式" required>
            <a-radio-group v-decorator="['isOrderSign']" default-value="0">
              <a-radio value="0"> 默认签署 </a-radio>
              <a-radio value="1"> 指定签署 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

    <a-modal
      centered
      :title="inputModalParams.title"
      :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading"
      :cancelText="inputModalParams.cancelText"
      :okText="inputModalParams.okText"
      :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose"
      :width="inputModalParams.width"
      @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel"
    >
      <div>
        <a-form :form="form2" v-bind="formItemLayout">
          <a-form-item label="作废原因" has-feedback>
            <a-input
              type="textarea"
              :placeholder="l('请输入')"
              v-decorator="[
                'invalidReason',
                {
                  rules: [
                    { required: true, message: this.l('ThisFieldIsRequired') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { ProjectAgreementServiceProxy } from "./services/project-agreement-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import EleTable from "@/components/ele-table";
import ConfigList from "./config-list";
import ConfigListOrg from "./config-list-org";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import PreAgreementList from './pretemplate-list';
import { Modal } from 'ant-design-vue';

export default {
  name: "templete-list",
  mixins: [AppComponentBase, ModalComponentBase],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      // 表单
      formLayout: "horizontal",
      form1: this.$form.createForm(this, { name: "coordinated" }),
      form2: this.$form.createForm(this, { name: "coordinated" }),
      isDisabled: false,
      tableKey: "",
      commonService: null,
      projectService: null,
      tableData: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      actionsType: {},
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      seclectModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        confirmDisabled: { props: { disabled: true } },
        visible: false,
        width: 620,
        title: "",
        form: {},
        id: null,
        selectList: [],
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.seclectModalParams.visible = false;
        },
      },
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        form: {},
        id: null,
        selectList: [],
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
      // 用户表格
      columns: [
        {
          title: this.l("协议模板名称"),
          dataIndex: "name",
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("签署方式"),
          dataIndex: "isOrderSignStr",
          sorter: false,
          width: 80,
          align: "center",
          scopedSlots: { customRender: "isOrderSignStr" },
        },
        {
          title: this.l("是否作废"),
          dataIndex: "isInvalidStr",
          width: 80,
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "isInvalidStr" },
        },
        {
          title: this.l("当前版本"),
          dataIndex: "agreementTemplateVersion",
          sorter: false,
          width: 75,
          align: "center",
          scopedSlots: { customRender: "agreementTemplateVersion" },
        },
        {
          title: this.l("最新版本"),
          dataIndex: "agreementTemplateLatestVersion",
          sorter: false,
          width: 75,
          align: "center",
          scopedSlots: { customRender: "agreementTemplateLatestVersion" },
        },
        {
          title: this.l("作废原因"),
          dataIndex: "invalidReason",
          width: 200,
          align: "center",
          scopedSlots: { customRender: "invalidReason" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.projectService = new ProjectAgreementServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.fullData(); // 模态框必须,填充数据到data字段
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  mounted() {
    if (this.params) {
      this.$nextTick(() => {});
    }
    this.initActionsType();
    this._getListByProjectId();
    this._agreementTemplateGetSelectData();
  },
  methods: {
    async _getListByProjectId() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/GetListByProjectId",
          params: { projectId: this.id },
        });
        this.tableData = res.map((item) => {
          return {
            ...item,
            isInvalidStr: item.isInvalid ? "是" : "否",
            invalidedTime: item.invalidedTime
              ? moment(item.invalidedTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            invalidReason: item.invalidReason ? item.invalidReason : "-",
            isDefaultStr: item.isDefault ? "是" : "否",
            isOrderSignStr: item.isOrderSign ? "指定签署" : "默认签署",
          };
        });
        this.totalItems;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _agreementTemplateGetSelectData() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/AgreementTemplate/GetSelectDataForUnnaturalPerson",
          params: null,
        });
        this.spinning = false;
        this.seclectModalParams.selectList = res;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _isAgreementSigningMethods(projectAgreementId) {
      let _this = this;
      try {
        let res = await _this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/IsAgreementSigningMethods",
          params: {
            projectId: _this.id,
            agreementTemplateId: projectAgreementId,
          },
        });
        if (typeof res === "boolean" && res === false) {
          _this.$message.info("没有授权自动签，不能选自动！");
          _this.seclectModalParams.confirmDisabled.props.disabled = true;
        } else {
          _this.seclectModalParams.confirmDisabled.props.disabled = false;
        }
      } catch (error) {}
    },
    addTemplete() {
      let _this = this;
      this.seclectModalParams.title = "选择协议模版";
      this.seclectModalParams.confirmDisabled.props.disabled = true;
      this.seclectModalParams.confirm = function () {
        _this.form1.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.seclectModalParams.confirmLoading = true;
            try {
              let res = await _this.commonService.post({
                url: "/api/services/app/UnnaturalPersonProjectAgreement/Insert",
                params: {
                  ...values,
                  isOrderSign: values.isOrderSign === "1" ? true : false,
                  projectId: _this.id,
                },
              });
              _this.seclectModalParams.confirmLoading = false;
              _this.seclectModalParams.visible = false;
              _this._getListByProjectId();
            } catch (error) {
              console.log(error);
              _this.seclectModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.seclectModalParams.visible = true;
    },
    editTemplete(item) {
      let _this = this;
      this.seclectModalParams.visible = true;
      this.seclectModalParams.title = "修改协议模版";
      this.seclectModalParams.confirmDisabled.props.disabled = false;
      setTimeout(() => {
        this.form1.setFieldsValue({
          // isDefault: item.isDefault,
          isOrderSign: item.isOrderSign ? "1" : "0",
        });
      }, 200);

      this.seclectModalParams.confirm = function () {
        _this.form1.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.seclectModalParams.confirmLoading = true;
            try {
              let res = await _this.commonService.put({
                url: "/api/services/app/UnnaturalPersonProjectAgreement/Update",
                params: {
                  ...values,
                  isOrderSign: values.isOrderSign === "1" ? true : false,
                  id: item.id,
                },
              });
              _this.seclectModalParams.confirmLoading = false;
              _this.seclectModalParams.visible = false;
              _this._getListByProjectId();
            } catch (error) {
              console.log(error);
              _this.seclectModalParams.confirmLoading = false;
            }
          }
        });
      };
    },
    setInvalid(projectAgreementId) {
      let _this = this;
      this.inputModalParams.title = "填写作废原因";
      this.inputModalParams.confirm = function () {
        _this.form2.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.inputModalParams.confirmLoading = true;
            try {
              let _res = await _this.commonService.get({
                url: "/api/services/app/UnnaturalPersonProjectAgreement/SetInvalid",
                params: {
                  projectAgreementId: projectAgreementId,
                  invalidReason: values.invalidReason,
                },
              });
              _this.inputModalParams.confirmLoading = false;
              _this.inputModalParams.visible = false;
              _this._getListByProjectId();
            } catch (error) {
              _this.inputModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.inputModalParams.visible = true;
    },
    previewTemplete(item) {
      console.log(item)
      if(item.pdfTemplateUrl==null)   
      {
        this.success({url:  `/app/fs/addSignMethodv2/${item.agreementTemplateId}/0?type=${item.agreementGroupType}&projectId=${item.unnaturalPersonProjectId}&projectName=${item.projectName}`})
        //  this.$router.push({
        //   path: `/app/fs/addSignMethodv2/${item.agreementTemplateId}/0?type=${item.agreementGroupType}&projectId=${item.unnaturalPersonProjectId}&projectName=${item.projectName}`,
        //  });
      }else{
          window.open(`${AppConsts.uploadBaseUrl}${item.pdfTemplateUrl}`);
      } 
    },
    syncTemplete(item) {
      const that = this;
      const latestPdfTemplateUrl = `${AppConsts.uploadBaseUrl}${item.latestPdfTemplateUrl}`;
      this.$confirm({
        title: "确定要将协议模板更新到最新吗？",
        content: (h) => (
          <div>
            <div style="color:red;">
              确定更新后项目协议将使用最新的模板进行签署。
            </div>
            <a href={latestPdfTemplateUrl} target="_blank">
              点击预览模板
            </a>
          </div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk() {
          const closeLoading = that.$message.loading("正在更新中...", 0);
          that.commonService
            .get({
              url: "/api/services/app/UnnaturalPersonProjectAgreement/SyncToAppointVersionAgreementTemplate",
              params: {
                id: item.id,
              },
            })
            .then((_) => {
              that._getListByProjectId();
              closeLoading();
              that.$message.success("更新成功", 1.5);
            })
            .catch((_) => {
              closeLoading();
            });
        },
      });
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "templete-list",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("预览模板"),
              icon: "rocket",
              fn: (data) => {
                _this.previewTemplete(data);
              },
            },
            {
              granted: true,
              name: this.l("更新模板"),
              icon: "sync",
              fn: (data) => {
                _this.syncTemplete(data);
              },
            },
            {
              granted: true,
              name: this.l("修改"),
              icon: "edit",
              fn: (data) => {
                _this.editTemplete(data);
              },
            },
            {
              granted: true,
              name: this.l("修改印章"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data.id, "edit");
              },
            },
            {
              granted: true,
              name: this.l("修改签署规则"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEditOrg(data.id, "edit");
              },
            },
            {
              granted: true,
              name: this.l("作废"),
              icon: "delete",
              fn: (data) => {
                if (!data.isInvalid) {
                  _this.setInvalid(data.id);
                } else {
                  _this.$message.info("该协议已作废");
                }
              },
            },
            {
              granted: true,
              name: this.l("前置协议"),
              icon: "pic-left",
              fn: (data) => {
                if (!data.isInvalid) {
                  _this.preAgreementList(data);
                } else {
                  _this.$message.info("该协议已作废");
                }
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    createOrEdit(id) {
      ModalHelper.create(
        ConfigList,
        {
          projectAgreementId: id,
        },
        {
          isChange: true,
          width: "960px",
        }
      ).subscribe((res) => {
        this._getListByProjectId();
      });
    },
    createOrEditOrg(id) {
      ModalHelper.create(
        ConfigListOrg,
        {
          projectAgreementId: id,
        },
        {
          isChange: true,
          width: "960px",
        }
      ).subscribe((res) => {
        this._getListByProjectId();
      });
    },
    preAgreementList (data) {
      ModalHelper.create(PreAgreementList, 
        {
          agreementId: data.id,
          projectId: this.id
        },
        {
          isChange: true,
          width: "750px",
        }
      );
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
</style>
