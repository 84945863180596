var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[(_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l("Edit")))]):_vm._e(),(!_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l("Create")))]):_vm._e()])]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 20 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"场景名称","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              {
                required: true,
                message: _vm.l('validation.required'),
              } ],
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('validation.required'),\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"请输入场景名称"}})],1),_c('a-form-item',{attrs:{"label":"备注","has-feedback":""}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remark',
          {
            rules: [{}],
          } ]),expression:"[\n          'remark',\n          {\n            rules: [{}],\n          },\n        ]"}],attrs:{"placeholder":"请输入备注"}})],1)],1),_c('div',{staticClass:"btn-w"},[_c('a-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.l("Save"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }