<template>
  <a-spin :spinning="spinning">
    <section>
      <div class="modal-header" v-if="!isShow">
        <div class="modal-title">
          <a-icon type="unordered-list" />项目场景协议管理
        </div>
      </div>
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :actions-type="actionsType"
        :is-full="false"
        :hide-row-selection="true"
        :hide-pagination="true"
      >
        <!-- <a-row :gutter="24" type="flex" style="text-align: right">
          <a-col class="gutter-row" :span="10" offset="14">

          </a-col>
        </a-row> -->
      </ele-table>
      <div class="modal-footer">
        <a-button @click="close"
          ><a-icon type="close-circle" />{{ l("关闭") }}</a-button
        >
        <a-button type="primary" @click="createOrEdit(null)"
          ><a-icon type="plus" />{{ l("添加") }}</a-button
        >
      </div>
    </section>
    <a-modal
      centered
      :title="inputModalParams.title"
      :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading"
      :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose"
      :width="inputModalParams.width"
      :footer="null"
      @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel"
    >
      <div style="text-align: center; position: relative">
        <a-spin :spinning="spinning" tip="加载中..." style="z-index: 1">
          <div style="height: 245px; width: 245px"></div>
        </a-spin>
        <div style="position: absolute; z-index: 2; top: 0; left: 55px">
          <img :src="inputModalParams.url" />
        </div>
        <div style="margin-top: 10px">
          项目名称/场景：{{ inputModalParams.desc.projectName }}/{{
            inputModalParams.desc.sceneName
          }}
        </div>
        <div>项目编码：{{ inputModalParams.desc.code }}</div>
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import EleTable from "@/components/ele-table";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import { message } from "ant-design-vue";
import CreateOrEditProjectSceneAgreement from "./add-or-edit-project-scene-agreement.vue";
import { appSessionService } from "@/shared/abp";

export default {
  name: "project-scene-agreement-list",
  mixins: [AppComponentBase, ModalComponentBase],
  props: ["isShow"],
  components: { EleTable },
  data() {
    return {
      // isShow:false,
      spinning: false,
      // 表单
      formLayout: "horizontal",
      isDisabled: false,
      tableKey: "",
      commonService: null,
      // projectType:0,
      tableData: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      actionsType: {},
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },

      // 用户表格
      columns: [
        {
          title: "协议模板名称",
          dataIndex: "agreementName",
          sorter: false,
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          dataIndex: "creationTime",
          sorter: false,
          align: "center",
          width: 100,
          customRender: function (text, record, index) {
            if (text) {
              return text.split("T")[0];
            }
            return "";
          },
          scopedSlots: { customRender: "creationTime" },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          scopedSlots: { customRender: "actions" },
          // fixed: "right",
          align: "center",
        },
      ],
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 400,
        title: "项目场景二维码",
        url: "",
        desc: {
          nane: "",
          code: "",
        },
        cancelText: "取消",
        okText: "确认",
        form: {},
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.confirm = () => {};
          this.inputModalParams.visible = false;
        },
      },
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.fullData(); // 模态框必须,填充数据到data字段
  },
  mounted() {
    if (this.params) {
      this.$nextTick(() => {});
    }
    this.initActionsType();
    this._getList();
  },
  methods: {
    async _getList() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectScene/GetProjectSceneAgreementList",
          params: { sceneId: this.id },
        });
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            creationTime: moment(item.creationTime).format("YYYY-MM-DD"),
          };
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    deleteItem(id) {
      this.commonService
        .delete({
          url: "/api/services/app/UnnaturalPersonProjectScene/DeleteProjectSceneAgreement",
          params: id,
        })
        .then((res) => {
          console.log(res);
          if (res) {
            message.success("删除成功！");
            this.$nextTick(() => {
              this._getList();
            });
          } else {
            message.error("删除失败！");
          }
        });
    },
    createOrEdit() {
      ModalHelper.create(
        CreateOrEditProjectSceneAgreement,
        {
          id: this.id,
          unnaturalPersonProjectId: this.unnaturalPersonProjectId,
        },
        {
          width: "600px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          this._getList();
        }
      });
    },
    initActionsType() {
      let _this = this;
      let obj = {
        isShow: true,
        fns: {
          dropList: [
            // {
            //   granted: true,
            //   name: this.l("项目场景二维码"),
            //   icon: "qrcode",
            //   fn: (data) => {
            //     _this._getProjectSceneCode(data);
            //   },
            // },
            // {
            //   granted: true,
            //   name: this.l("Delete"),
            //   icon: "delete",
            //   fn: (data) => {
            //     _this.deleteItem(data.id);
            //   },
            // },
          ],
          delete: {
            granted: true,
            name: this.l("Delete"),
            fn: (data) => {
              _this.deleteItem(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    _close() {
      if (this.isShow) {
        this.$emit("emitclose");
      } else {
        this.close();
      }
    },
    _getProjectSceneCode(data) {
      let _this = this;
      _this.inputModalParams.desc.projectName = data.projectName;
      _this.inputModalParams.desc.sceneName = data.name;
      _this.inputModalParams.desc.code = data.projectCode;
      if (appSessionService.tenantId == null) {
        _this.inputModalParams.url = `${this.$apiUrl}/api/services/app/UnnaturalPersonProject/GetQrCodeById?projectId=${data.unnaturalPersonProjectId}&sceneId=${data.id}`;
      } else {
        _this.inputModalParams.url = `${this.$apiUrl}/api/services/app/UnnaturalPersonProject/GetQrCodeById?projectId=${data.unnaturalPersonProjectId}&sceneId=${data.id}&tenantId=${appSessionService.tenantId}`;
      }

      _this.inputModalParams.visible = true;
    },
  },
};
</script>

<style scoped lang="less">
.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}

.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
.mf {
  margin: 0;
  margin-top: 10px;
}
</style>
