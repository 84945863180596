<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="id">{{ l("Edit") }}</span>
        <span v-if="!id">{{ l("Create") }}</span>
      </div>
    </div>
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 20 }" @submit="handleSubmit"
      layout="horizontal">
      <div style="max-height: 60vh; overflow-x: hidden; overflow-y: auto;">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item :label="l('组织架构')" :labelCol="{ span: 6 }">
              <a-select :disabled="id ? true : false" :placeholder="l('请选择组织架构')" v-decorator="[
                'OrganizationUnitId',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]" style="width: 100%">
                <a-select-option :value="item.value" v-for="(item, index) in OrganizationData" :key="index">
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 项目名称 -->
            <a-form-item :label="l('ProjectHeaderName')" :labelCol="{ span: 6 }">
              <a-input :placeholder="l('ProjectHeaderName')" v-decorator="[
                'ProjectName',
                {
                  rules: [
                    {
                      required: true,
                      message: l('RequiredProjectName'),
                    },
                    {
                      min: 3,
                      message: l('MinProjectNameLen'),
                    },
                  ],
                },
              ]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <!-- 项目编码 -->
            <a-form-item :label="l('ProjectHeaderCode')" :labelCol="{ span: 6 }">
              <a-input :placeholder="l('ProjectHeaderCode')" disabled v-decorator="[
                'ProjectCode',
                {
                  rules: [
                    {
                      required: true,
                      message: l('RequiredProjectCode'),
                    },
                  ],
                },
              ]" />
            </a-form-item>
          </a-col>
          <a-col span="12">
            <a-form-item :label="l('ProjectType')" :labelCol="{ span: 6 }">
              <a-select :placeholder="l('ProjectType')" v-decorator="[
                'ProjectType',
                {
                  rules: [
                    {
                      required: true,
                      message: l('RequiredProjectType'),
                    },
                  ],
                },
              ]">
                <a-select-option v-for="item in ProjectTypeData" :key="item.value" :value="item.value">{{ item.text
                  }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="所属客户" :labelCol="{ span: 6 }">
              <a-input :placeholder="l('输入内容')" v-decorator="[
                'CustomerName',
                {
                  rules: [
                    {
                      required: true,
                      message: l('RequiredProjectName'),
                    },
                  ],
                },
              ]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!--项目时间-->
            <a-form-item :label="l('ProjectHeaderTime')" :labelCol="{ span: 6 }">
              <a-range-picker :placeholder="['开始日期', '结束日期']" :locale="locale" valueFormat="YYYY-MM-DD" v-decorator="[
                'ProjectTime',
                {
                  rules: [
                    {
                      required: true,
                      message: l('RequiredProjectTime'),
                    },
                  ],
                },
              ]">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date">
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item :label="l('实名认证类型')" :labelCol="{ span: 8 }">
              <a-select show-search v-decorator="[
                `verifiedWay`,
                {
                  rules: [{ required: true, message: '请选择实名认证类型' }],
                },
              ]" option-filter-prop="children" placeholder="请选择实名认证类型">
                <a-select-option value="4">
                  纯三要素
                </a-select-option>
                <a-select-option value="5">
                  纯四要素
                </a-select-option>
                <a-select-option value="9">
                  人脸识别
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="l('签署范围')" :labelCol="{ span: 6 }">
              <a-select show-search v-decorator="[
                `signatureType`,
                {
                  rules: [{ required: true, message: '请选择签署范围' }],
                },
              ]" option-filter-prop="children" placeholder="请选择签署范围">
                <a-select-option value="0">
                  未签署人员
                </a-select-option>
                <a-select-option value="1">
                  所有人员
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12" v-for="(item, index) in FormItems" :key="index">
            <a-form-item :label="item.label" :required="item.required" :props="`el_${item.value}`"
              :label-col="{ span: item.label.length > 5 ? 10 : 6 }">
              <a-input :placeholder="l(item.val)" @change="item.changeFn" v-decorator="[
                `e_${item.value}`,
                {
                  rules:
                    item.rules && item.rules.length ? item.rules : defaultRules,
                },
                { initialValue: item.val, defaultValue: item.val },
              ]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="8">
            <a-form-item :label="l('退休年龄提示')" :labelCol="{ span: 12 }">
              <a-switch v-decorator="['ageTipEnabled']" :checked="showAgeArea" :unCheckedChildren="l('关闭')"
                @change="(e) => showAgeArea = e" :checkedChildren="l('开启')" />
            </a-form-item>
          </a-col>
          <a-col span="8" :style="`display:${(showAgeArea ? 'block' : 'none')}`">
            <a-form-item :label="l('男')">
              <a-input-number v-decorator="['manRetirementAge']" :min="1" :max="150" :step="1" :parser="valueFormatter"
                :formatter="showFormatter" />
            </a-form-item>
          </a-col>
          <a-col span="8" :style="`display:${(showAgeArea ? 'block' : 'none')}`">
            <a-form-item :label="l('女')">
              <a-input-number v-decorator="['womanRetirementAge']" :min="1" :max="150" :step="1"
                :parser="valueFormatter" :formatter="showFormatter" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <a-form-item :label="l('项目人员信息脱敏')" :labelCol="{ span: 12 }">
              <a-switch v-decorator="['isDesensit', { valuePropName: 'checked' }]" :unCheckedChildren="l('关闭')"
                :checkedChildren="l('开启')" />
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <a-row :gutter="24">
        <a-col span="24">
          <a-form-item class="btn--container" style="text-align: center">
            <a-button type="button" @click="close()">
              {{ l("Cancel") }}
            </a-button>
            <a-button type="primary" html-type="submit">
              {{ l("Save") }}
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>

    </a-form>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ProjectServiceProxy } from "./services/project-proxy";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-project",
  data () {
    return {
      // 获取到的数据
      locale,
      entity: {},
      spinning: false,
      TimeInterval: null,
      Proxy: "",
      ClientData: [],
      CompanyData: [],
      ContractData: [],
      InvoiceData: [],
      ProjectTypeData: [],
      OrganizationData: [],
      FormItems: [],
      showAgeArea: false
    };
  },
  components: {},
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  mounted () {
    this.$nextTick(async () => {
      await this.getExtendInfo();
      this.getData();
    });
  },
  created () {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.Proxy = new ProjectServiceProxy(this.$apiUrl, this.$api);
    this.getProjectType();
    this.getAllContract();
    this.getAllCustomerBaseInvoices("");
    this.getOrganization();
  },
  methods: {
    showFormatter (value) {
      return value || value === 0 ? `${value}岁` : value;
    },
    valueFormatter (value) {
      return value ? value.replace('岁', '') : value;
    },
    async getExtendInfo () {
      this.spinning = true;
      try {
        const res = await this.$api.request({
          url: this.$apiUrl + "/api/services/app/UnnaturalPersonProject/GetProjectExtendInfoById",
          method: "GET",
          params: {
            id: this.id
          }
        });
        if (res.data && res.data.formItems && res.data.formItems.length > 0) {
          const list = [];
          const results = res.data.results ? res.data.results : {};
          res.data.formItems.map((_item) => {
            const row = results[`${_item.key}`];
            const rowVal = row && row.value && row.value !== '' ? row.value : null;
            list.push({
              label: _item.name,
              value: _item.key,
              val: rowVal,
              defaultValue: rowVal,
              type: "commonInput",
              disabled: false,
              required: _item.isRequire,
              key: _item.key,
              rules: [
                { max: _item.maxLength, message: this.l("MaxLength") },
              ],
              span: 24,
              changeFn: () => { },
            });
            return null;
          });
          this.FormItems = list;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.spinning = false;
      }
    },
    /**
     * 获取数据
     */
    getData () {
      this.spinning = true;
      if (this.id) {
        this.Proxy.GetProject(this.id)
          .finally(() => {
            this.spinning = false;
          })
          .then((res) => {
            if (!res) {
              return false;
            }
            let formData = {
              ProjectName: res.projectName,
              ProjectCode: res.projectCode,
              ProjectType: this.convertData(3, res.projectType),
              OrganizationUnitId: res.organizationUnitId.toString(),
              ProjectTime: [res.beginDate, res.endDate],
              CustomerName: res.customerName,
              Id: this.Id,
              verifiedWay: this.convertData(1, res.verifiedWay),
              signatureType: this.convertData(2, res.signatureType),
              ageTipEnabled: res.ageTipEnabled,
              manRetirementAge: res.manRetirementAge,
              womanRetirementAge: res.womanRetirementAge,
              isDesensit: res.isDesensit
            };
            if (res.results && res.results.length > 0) {
              const formValues = {};
              res.results.map((item) => {
                formValues[`el_${item.key}`] = item.value?.value;

                return null;
              });
              //formData = { ...formData, ...formValues };
            }
            this.showAgeArea = res.ageTipEnabled;
            this.entity = formData;
            this.form.setFieldsValue(formData);
          });
      } else {
        this.Proxy.getProjectCode()
          .finally(() => {
            this.spinning = false;
          })
          .then((res) => {
            if (res) {
              this.form.setFieldsValue({
                ProjectCode: res,
              });
            }
          });
        this.form.setFieldsValue({ isDesensit: true });
      }
    },
    convertData (type, value) {
      if (type === 1) {
        switch (value) {
          default:
            return value;
          case 'ThreeElements':
            return '4';
          case 'FourElements':
            return '5';
          case 'FaceRecognition':
            return '9';
        }
      } else if (type === 2) {
        switch (value) {
          default:
            return value;
          case 'NotSigned':
            return '0';
          case 'All':
            return '1';
          case 'Signed':
            return '2';
        }
      } else {
        switch (value) {
          default:
            return value;
          case 'RenLiWaiBao':
            return '1';
          case 'XiangMuZiXun':
            return '2';
          case 'TuiGuangFuWu':
            return '3';
          case 'LuYanHuiZhan':
            return '4';
          case 'XinXiJiShu':
            return '5';
          case 'EconomicEntity':
            return '6';
        }
      }
    },
    /**
     * 提交表单
     */
    handleSubmit (e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.spinning = true;
          try {
            const params = {};
            const result = [];
            if (values.ageTipEnabled) {
              if (!values.manRetirementAge || !values.womanRetirementAge) {
                this.spinning = false;
                this.$notification["error"]({
                  message: this.l("请填写男女退休年龄"),
                });
                return;
              }
            }
            for (var key in values) {
              if (key.indexOf('e_') === 0) {
                const itemKey = key.replace('e_', '');
                let label = '';
                const items = this.FormItems.filter(it => it.value === itemKey);
                if (items && items.length > 0) {
                  label = items[0].label;
                }
                result.push({
                  key: itemKey,
                  value: {
                    label: label,
                    value: values[key],
                  }
                })
              } else {
                params[key] = values[key];
              }
            }
            params.results = result;
            this.FormItems.map(it => {
              const rows = params.results.filter(i => i.key === it.value);
              let item = rows && rows.length > 0 ? rows[0] : null;
              if (!item) {
                item = { key: it.value, value: { label: it.label, value: it.val } };
              } else {
                let val = item.value.value;
                if (!val || val === '') {
                  val = it.val;
                }
                item.value.value = val;
              }
              params.results[it.value] = item;
            });
            if (params.ProjectTime && params.ProjectTime.length === 2) {
              params.beginDate = params.ProjectTime[0];
              params.endDate = params.ProjectTime[1];
              delete params.ProjectTime;
            }
            let postUrl = `${this.$apiUrl}/api/services/app/UnnaturalPersonProject/Create`;
            if (this.id) {
              postUrl = `${this.$apiUrl}/api/services/app/UnnaturalPersonProject/Update`;
              params.id = this.id;
            }
            this.$api.request({
              url: postUrl,
              method: "POST",
              data: params
            }).finally(() => {
              this.spinning = false;
            })
              .then((res) => {
                this.$notification["success"]({
                  message: this.l("SavedSuccessfully"),
                });
                this.success(true);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          catch (e) {
            console.error(e);
            this.spinning = false;
          }
        }
      });
    },
    getProjectType () {
      this.Proxy.getEnumKeyValueList(1).then((res) => {
        if (res && res.length > 0) {
          let array = res;
          for (let i = 0; i < array.length; i++) {
            let item = array[i];
            this.ProjectTypeData.push({ text: item.key, value: item.value });
          }
        }
      });
    },
    getAllContract () {
      this.Proxy.getAllContract().then((res) => {
        this.ContractData.splice(0, this.ContractData.length);
        if (res.totalCount > 0) {
          for (let i = 0; i < res.items.length; i++) {
            this.ContractData.push(res.items[i]);
          }
          if (this.entity && this.entity.contractId) {
            var items = this.ContractData.filter(
              (p) => p.id === this.entity.contractId
            );
            if (items && items.length > 0) {
              this.getAllCustomerBaseInvoices(items[0].customerId);
            }
          }
        }
      });
    },
    ContractChange (value, e) {
      let customerId = "";

      if (value) {
        let company = null;
        for (let i = 0; i < this.ContractData.length; i++) {
          let item = this.ContractData[i];
          if (item.id === value) {
            company = {
              id: item.organizationUnitId,
              name: item.organizationUnitName,
            };
            customerId = item.customerId;
            break;
          }
        }
        if (company !== null) {
          this.CompanyData.splice(0, this.CompanyData.length);
          this.CompanyData.push(company);
          this.form.setFieldsValue({
            Company: { key: company.id, label: company.name },
          });
        }
      } else {
        this.CompanyData.splice(0, this.CompanyData.length);
      }

      if (customerId !== "") {
        this.getAllCustomerBaseInvoices(customerId);
      }
    },
    getAllCustomerBaseInvoices (customerId) {
      this.Proxy.getAllCustomerBaseInvoices(customerId).then((res) => {
        this.InvoiceData.splice(0, this.InvoiceData.length);
        if (res.totalCount > 0) {
          for (let i = 0; i < res.items.length; i++) {
            let { id, title } = res.items[i];
            this.InvoiceData.push({ id: id, name: title });
            if (
              this.entity &&
              this.entity.invoiceId &&
              this.entity.invoiceId === id
            ) {
              this.form.setFieldsValue({
                Invoice: { key: id, label: title },
              });
            }
          }
        }
      });
    },
    getOrganization () {
      let _that = this;
      this.Proxy.getOrganization().then((res) => {
        _that.OrganizationData.push(...res);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container .ant-form-item-children {
  display: block;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
