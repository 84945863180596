<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :scroll="{ x: 1300 }"
      :columns="columns"
      :hide-row-selection="true"
      :is-full="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table :columns="columns" :table-data="tableData" :total-items="totalItems" :is-full="true"
      :actionsType="actionsType" :current-page="pageNumber" :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh" @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange" @emitShowSizeChange="updateShowSizeChange" @emitLinkClick="linkClick">
      <a-row :gutter="8">
        <!--<a-col class="gutter-row" :span="3">
          <a-input
            v-model.trim="SearchDto.ProjectName"
            :placeholder="l('ProjectHeaderName')"
          />
        </a-col>-->
        <a-col :span="3">
          <a-input v-model="SearchDto.ProjectName" placeholder="请选择项目" @click="selectProjectModal">
          </a-input>
          <a-input v-model="SearchDto.ProjectId" type="hidden"> </a-input>
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-input v-model.trim="SearchDto.ProjectCode" :placeholder="l('ProjectHeaderCode')" />
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-select v-model="SearchDto.CustomerName" style="width: 100%" show-search :filter-option="filterOption"
            :placeholder="l('PleaseSelectCustomer')" allowClear>
            <a-select-option v-for="item in Customers" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3">
          <a-input v-model.trim="SearchDto.ProjectJoiner" placeholder="项目参与人">
            <a-icon slot="prefix" type="key" style="color: rgba(0, 0, 0, 0.25)" />
          </a-input>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-range-picker :placeholder="[l('开始日期'), l('结束日期')]" v-model="startToEndDate" />
        </a-col>

        <a-col class="gutter-row" :span="3">
          <a-button type="primary" @click="search" v-if="isGranted('Pages.UnnaturalProjectPermissions.Query')">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" :span="5" style="text-align: right">
          <a-button-group>
            <a-button type="primary" @click="createReactModify1(null, null)"
              v-if="isGranted('Pages.UnnaturalProjectPermissions.Create')">
              <a-icon type="plus" />
              {{ l("Create") }}
            </a-button>
            <a-button @click="exportToExcel()" v-if="isGranted('Pages.UnnaturalProjectPermissions.ProjectExport')">
              <a-icon type="file-excel" />
              <span>{{ l("ExportToExcel") }}</span>
            </a-button>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- <a-row :gutter="8" class="btn--container">
        <a-col class="gutter-row" :span="24" style="text-align: right">
          <a-button
            type="primary"
            @click="createReactModify(null, 'others')"
            v-if="isGranted('Pages.UnnaturalProjectPermissions.Create')"
          >
            <a-icon type="plus" />
            {{ l("Create") }}
          </a-button>
          <a-button
            @click="exportToExcel()"
            v-if="
              isGranted('Pages.UnnaturalProjectPermissions.ProjectExport')
            "
          >
            <a-icon type="file-excel" />
            <span>{{ l("ExportToExcel") }}</span>
          </a-button>
        </a-col>
      </a-row> -->
    </ele-table>
    <a-modal centered :title="inputModalParams.title" :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading" :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose" :width="inputModalParams.width" :footer="null"
      @ok="inputModalParams.confirm" @cancel="inputModalParams.cancel">
      <div style="text-align: center">
        <img :src="inputModalParams.url" />
        <div style="margin-top: 10px">
          项目名称：{{ inputModalParams.desc.name }}
        </div>
        <div>项目编码：{{ inputModalParams.desc.code }}</div>
      </div>
    </a-modal>
  </a-spin>
</template>
<script>
import { appSessionService } from "@/shared/abp";
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import {
  ProjectServiceProxy,
  ProjectSearchDto,
} from "./services/project-proxy";
import { fileDownloadService } from "@/shared/utils";
import CreateOrUpdate from "./createproject.vue";
import TempleteList from "./project-agreement/templete-list";
import authedUserList from "./project-form/authed-user-list";
import ProjectForm from "./project-form/project-form";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import SelectPageList from "@/components/linggong/select-page-list";
import SceneList from "./project-scene/project-scene-list.vue";

export default {
  mixins: [AppComponentBase],
  name: "projectmodule",
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      SearchDto: {
        ProjectCode: "",
        ProjectName: "",
        ProjectId: "",
        CustomerName: undefined,
        CustomerId: undefined,
        ProjectJoiner: "",
        CompanyName: "",
        StartDate: undefined,
        EndDate: undefined,
        Sorting: "",
        skipCount: 0,
        MaxResultCount: 50,
      },
      Proxy: null,
      commonService: null,
      CompanyData: [{ text: "", value: "" }],
      selectedRowKeys: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [],
      startToEndDate: [],
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 400,
        title: "项目二维码",
        url: "",
        desc: {
          nane: "",
          code: "",
        },
        cancelText: "取消",
        okText: "确认",
        form: {},
        confirm: () => { },
        cancel: () => {
          this.inputModalParams.confirm = () => { };
          this.inputModalParams.visible = false;
        },
      },
      ProjectTypeData: [],
      Customers: [], // { value: "", label: this.l("PleaseSelectCustomer") }
    };
  },
  methods: {
    selectProjectModal () {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/UnnaturalPersonProject/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        console.log(res);
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }

          this.SearchDto.ProjectId = projectIds; //data.id;
          this.SearchDto.ProjectName = projectNames; //data.name;

          this.getData();
          // this.getTaskByProjectId(0, data.id);
        }
      });
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    openAuthForm (id, data) {
      ModalHelper.create(
        authedUserList,
        { id: id, projectType: 2 },
        { isChange: true, width: "800px" }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    getAllCutomer () {
      let options = {
        url:
          this.$apiUrl +
          "/api/services/app/Customer/GetCustomerForUnnaturalpersonOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    initActionsType () {
      let _this = this;
      let obj = {
        type: "project",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectPermissions.ProjectForm"
              ),
              name: this.l("项目表单"),
              icon: "profile",
              fn: (data) => {
                _this.projectFormModal(data.id);
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectPermissions.ProjectAgreement"
              ),
              name: this.l("协议设置"),
              icon: "profile",
              fn: (data) => {
                _this.projectAgreementModal(data.id);
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectPermissions.ProjectExtend"
              ),
              name: this.l("扩展信息"),
              icon: "profile",
              fn: (data) => {
                _this.createReactModify1(data, data.id);
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectPermissions.ProjectPower"
              ),
              name: "项目授权",
              icon: "edit",
              fn: (data) => {
                _this.openAuthForm(data.id, data);
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectPermissions.ProjectScene"
              ),
              name: "项目场景",
              icon: "qrcode",
              fn: (data) => {
                _this.projectSceneModal(data.id);
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    // 排序更新data
    updateSortData (newV) {
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange (newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    updateShowSizeChange (newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // table选择事件
    updateSelectChange (newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    // 清除条件并刷新
    clearFilterAndRefresh () {
      this.refreshGoFirstPage();
    },
    // 分页事件
    showTotalFun () {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    search () {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    getData () {
      this.spinning = true;
      var dto = new ProjectSearchDto();
      this.SearchDto.SkipCount =
        (this.pageNumber - 1) * this.SearchDto.MaxResultCount;
      this.SearchDto.StartDate =
        this.startToEndDate[0] === null ? undefined : this.startToEndDate[0];
      this.SearchDto.EndDate =
        this.startToEndDate[1] === null ? undefined : this.startToEndDate[1];
      dto = dto.formJson(this.SearchDto);
      this.Proxy.GetList(dto)
        .finally(() => {
          this.spinning = false;
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.items.map((item) => {
            return {
              ...item,
              // isAuditJoin: item.isAuditJoin ? "是" : "否",
              project_Code: "二维码",
              projecth5_Code: "H5二维码",
              beginDate: moment(item.beginDate).format("YYYY-MM-DD"),
              endDate: moment(item.endDate).format("YYYY-MM-DD"),
            };
          });
          this.totalItems = res.totalCount;
          this.totalPages = Math.ceil(
            res.totalCount / this.SearchDto.MaxResultCount
          );
          this.pagerange = [
            (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
            this.pageNumber * this.SearchDto.MaxResultCount,
          ];
        });
    },
    refreshGoFirstPage () {
      this.SearchDto.ProjectName = "";
      this.SearchDto.ProjectCode = "";
      this.SearchDto.CustomerName = undefined;
      this.SearchDto.CompanyName = "";
      this.SearchDto.ProjectJoiner = "";
      this.SearchDto.Sorting = "";
      this.SearchDto.StartDate = "";
      this.SearchDto.EndDate = "";
      this.startToEndDate = [];
      this.SearchDto.ProjectId = "";
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.CustomerId = undefined;
      this.getData();
    },
    createOrEdit (id, projectItem) {
      console.log(id);
      console.log(projectItem);
      ModalHelper.create(
        CreateOrUpdate,
        { id: id, projectItem: projectItem },
        {
          isChange: true,
          width: "600px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    async exportToExcel () {
      if (this.totalItems <= 0) {
        this.$message.warning(this.l("ProjectExportEmpty"));
        return false;
      }
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProject/GetToExcelFile",
          params: {
            startDate: (this.SearchDto.StartDate =
              this.startToEndDate[0] === null
                ? undefined
                : this.startToEndDate[0]),
            endDate: (this.SearchDto.EndDate =
              this.startToEndDate[1] === null
                ? undefined
                : this.startToEndDate[1]),
            projectCode:
              this.SearchDto.ProjectCode == ""
                ? null
                : this.SearchDto.ProjectCode,
            projectName:
              this.SearchDto.ProjectName == ""
                ? null
                : this.SearchDto.ProjectName,
            projectId:
              this.SearchDto.ProjectId == "" ? null : this.SearchDto.ProjectId,
            skipCount: this.SearchDto.SkipCount,

            maxResultCount: this.SearchDto.MaxResultCount,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
      // this.Proxy.getToExcelFile().then((result) => {
      //   this.spinning = false;
      //   fileDownloadService.downloadTempFile(result);
      // });
    },
    batchDelete () {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      this.message.confirm(
        this.l("ConfirmDeleteXItemsWarningMessage", selectCount),
        (res) => {
          if (res) {
            const ids = _.map(this.selectedRowKeys);
            this.spinning = true;
            this.Proxy.BatchDelete(ids)
              .finally(() => {
                this.spinning = false;
              })
              .then(() => {
                this.selectedRowKeys = [];
                this.refreshGoFirstPage();
                this.$notification["success"]({
                  message: this.l("SuccessfullyDeleted"),
                });
              });
          }
        }
      );
    },
    onChange (page, pageSize) {
      this.pageNumber = page;
      this.getData();
    },
    showSizeChange (current, size) {
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    deleteItem (item) {
      this.spinning = true;
      this.Proxy.DeleteProject(item.id)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    linkClick (newV) {
      const { item, index } = newV;
      if (index === 7) {
        this.getProjectCode(item);
      } else if (index === 8) {
        this.getProjectH5Code(item);
      }
    },
    restCheckStatus () {
      this.selectedRowKeys = [];
    },
    showTotalFun () {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    searchCompanyName (value, isAll) {
      if (isAll || (value && value.length > 2)) {
        this.Proxy.getCompanyName(value).then((res) => {
          console.info(res);
          this.CompanyData.splice(1, this.CompanyData.length);
          if (res.totalCount > 0) {
            for (let i = 0; i < res.items.length; i++) {
              this.CompanyData.push({
                text: res.items[i].name,
                value: res.items[i].name,
              });
            }
          }
        });
      }
    },
    projectAgreementModal (id) {
      ModalHelper.create(
        TempleteList,
        { id: id },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          if (res && res.url) {
            this.$router.push({
              path: res.url,
            });
          }
          this.refreshGoFirstPage();
        }
      });
    },
    projectFormModal (id) {
      ModalHelper.create(
        ProjectForm,
        { id: id },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    getProjectType () {
      this.Proxy.getEnumKeyValueList(1).then((res) => {
        if (res && res.length > 0) {
          let array = res;
          for (let i = 0; i < array.length; i++) {
            let item = array[i];
            this.ProjectTypeData.push({ text: item.key, value: item.value });
          }
        }
      });
    },
    getProjectUser (id) {
      // this.message.success("项目人员管理");
      this.$router.push({
        path: "/app/unnaturalperson/projectPersonel",
        query: {
          id: id,
        },
      });
    },
    initColumns () {
      let vm = this;
      this.columns = [
        {
          title: this.l("组织架构"),
          dataIndex: "organizationUnitName",
          sorter: false,
          align: "center",
          width: 240,
          ellipsis: true,
          scopedSlots: { customRender: "organizationUnitName" },
        },
        {
          title: this.l("ProjectHeaderName"),
          dataIndex: "projectName",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: this.l("项目编码"),
          dataIndex: "projectCode",
          sorter: false,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "projectCode" },
        },
        {
          title: this.l("ProjectHeaderCustomerName"),
          dataIndex: "customerName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "customerName" },
        },
        {
          title: this.l("ProjectType"),
          dataIndex: "projectTypeName",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "projectTypeName" },
        },
        {
          title: this.l("StartDateTime"),
          dataIndex: "beginDate",
          sorter: false,
          align: "center",
          customRender: function (text, record, index) {
            if (text) {
              return text.split("T")[0];
            }
            return "";
          },
          scopedSlots: { customRender: "beginDate" },
        },
        {
          title: this.l("EndDateTime"),
          dataIndex: "endDate",
          sorter: false,
          align: "center",
          customRender: function (text, record, index) {
            if (text) {
              return text.split("T")[0];
            }
            return "";
          },
          scopedSlots: { customRender: "endDate" },
        },
        {
          title: this.l("项目二维码"),
          dataIndex: "project_Code",
          // sorter: true,
          type: "link",
          align: "center",
          customRender: function (text, record, index) {
            var child = vm.$createElement("a", {
              domProps: {
                innerHTML: "二维码",
              },
              on: {
                click: function () {
                  vm.getProjectCode(record);
                },
              },
            });
            var obj = {
              children: child,
              attrs: {},
            };
            return obj;
          },
          scopedSlots: { customRender: "project_Code" },
        },
        {
          title: this.l("项目H5二维码"),
          dataIndex: "projecth5_Code",
          type: "link",
          // sorter: true,
          align: "center",
          customRender: function (text, record, index) {
            var child = vm.$createElement("a", {
              domProps: {
                innerHTML: "H5二维码",
              },
              on: {
                click: function () {
                  vm.getProjectH5Code(record);
                },
              },
            });
            var obj = {
              children: child,
              attrs: {},
            };
            return obj;
          },
          scopedSlots: { customRender: "projecth5_Code" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          width: 120,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    getProjectCode (data) {
      let _this = this;
      _this.inputModalParams.desc.name = data.projectName;
      _this.inputModalParams.desc.code = data.projectCode;
      if (appSessionService.tenantId == null) {
        _this.inputModalParams.url = `${this.$apiUrl}/api/services/app/UnnaturalPersonProject/GetQrCodeById?projectId=${data.id}`;
      } else {
        _this.inputModalParams.url = `${this.$apiUrl}/api/services/app/UnnaturalPersonProject/GetQrCodeById?projectId=${data.id}&tenantId=${appSessionService.tenantId}`;
      }

      _this.inputModalParams.visible = true;
      _this.inputModalParams.title = "项目二维码";
    },
    getProjectH5Code (data) {
      let _this = this;
      if (appSessionService.tenantId == null)
        _this.inputModalParams.url = `${this.$apiUrl}/api/services/app/UnnaturalPersonProject/GetH5QrCodeById?projectId=${data.id}`;
      else
        _this.inputModalParams.url = `${this.$apiUrl}/api/services/app/UnnaturalPersonProject/GetH5QrCodeById?projectId=${data.id}&tenantId=${appSessionService.tenantId}`;
      _this.inputModalParams.title = "项目H5二维码";
      _this.inputModalParams.desc.name = data.projectName;
      _this.inputModalParams.desc.code = data.projectCode;
      //_this.inputModalParams.url = `${this.$apiUrl}/api/services/app/UnnaturalPersonProject/GetH5QrCodeById?projectId=${data.id}&tenantId=${appSessionService.tenantId}`;
      _this.inputModalParams.visible = true;
    },
    async createReactModify (item, type) {
      let list = [
        {
          label: "组织架构",
          value: "OrganizationUnitId",
          type: "commonSelect",
          disabled: type == "edit",
          required: true,
          rules: [{ required: true, message: this.l("validation.required") }],
          span: 24,
          defaultValue: item ? item.organizationUnitId : "",
          defaultSelectList: await this._getOrgSelectList(),
          changeFn: () => { },
        },
        {
          label: "项目名称",
          value: "ProjectName",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [
            { max: 128, message: this.l("MaxLength") },
            { min: 3, message: this.l("MinProjectNameLen") },
          ],
          span: 24,
          changeFn: () => { },
        },
        {
          label: "项目编码",
          value: "ProjectCode",
          type: "commonInput",
          disabled: true,
          required: true,
          rules: [
            { max: 128, message: this.l("MaxLength") },
            { min: 3, message: this.l("MinProjectNameLen") },
          ],
          span: 24,
          defaultValue: await this._getProjectCode(),
          changeFn: () => { },
        },
        {
          label: "项目类型",
          value: "ProjectTypeName",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: this.l("RequiredProjectType") }],
          span: 24,
          defaultValue: item ? item.projectType : "",
          defaultSelectList: await this._getEnumNameValueArray(),
          changeFn: () => { },
        },
        {
          label: "项目时间",
          value: "ProjectTime",
          type: "rangeDatePicker",
          disabled: false,
          required: true,
          rules: [
            {
              type: "array",
              required: true,
              message: this.l("RequiredProjectTime"),
            },
          ],
          span: 24,
          defaultValue: null,
          changeFn: () => { },
        },
        {
          label: "所属客户",
          value: "CustomerName",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [{ required: true, message: this.l("validation.required") }],
          span: 24,
          changeFn: () => { },
        },
        {
          label: "实名认证类型",
          value: "VerifiedWay",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: this.l("validation.required") }],
          span: 24,
          defaultValue: item ? item.verifiedWay.toString() : "5",
          defaultSelectList: [
            // { label: "未设置", value: "0" },
            { label: "纯三要素", value: "4" },
            { label: "纯四要素", value: "5" },
            { label: "人脸识别", value: "9" },
          ],
          changeFn: () => { },
        },
        {
          label: "签署范围",
          value: "SignatureType",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: this.l("validation.required") }],
          span: 24,
          defaultValue: item ? item.signatureType.toString() : "",
          defaultSelectList: [
            { label: "未签署人员", value: "0" },
            { label: "所有人员", value: "1" },
          ],
          changeFn: () => { },
        },
      ];
      const orginListLength = list.length;
      // list = list.concat(_list)
      // console.log('list----', list)
      let reqParams = [];
      if (item && item.id) {
        // console.log(item)
        reqParams.push({ key: "id", value: item.id });
      }
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams],
            reqUrl: {
              getById:
                "/api/services/app/UnnaturalPersonProject/GetProjectExtendInfoById",
              save: "/api/services/app/UnnaturalPersonProject/Create",
              update: "/api/services/app/UnnaturalPersonProject/Update",
            },
            otherParams: {},
            initFn: async (form, options) => {
              // myForm = form
              // form.setFieldsValue({verifiedWay: '5'})
            },
            getItemfn: async (res, form, options) => {
              // form.setFieldsValue({...res})
              console.log(res);
              let formItems = res && res.formItems ? res.formItems : [];
              formItems.map((_item, index) => {
                switch (_item.itemType) {
                  case "Text":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonInput",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      changeFn: () => { },
                    });
                    break;
                  case "Textarea":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "textarea",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      changeFn: () => { },
                    });
                    break;
                  case "Number":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "numberInput",
                      disabled: false,
                      required: _item.isRequire,
                      // max: this.returnNumerInput(_item.maxLength),
                      max: _item.maxLength,
                      min: 0,
                      key: _item.key,
                      rules: [],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => { },
                    });
                    break;
                  case "Select":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonSelect",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: "",
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => { },
                    });
                    break;
                  case "Checkbox":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "checkbox",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: null,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => { },
                    });
                    break;
                  case "Radio":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "radio",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: null,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => { },
                    });
                    break;
                  case "Date":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "datePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => { },
                    });
                    break;
                  case "Upload":
                    list.push({
                      label: _item.name,
                      value: [],
                      type: "upload",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [],
                      subdirName: environment.production ? "abpProd" : "abpDev",
                      uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
                      limitedLength: _item.maxLength,
                      span: 24,
                      changeFn: (info) => {
                        let fileList = [...info.fileList];
                        fileList = fileList.map((file) => {
                          if (file.response) {
                            file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
                          }
                          return file;
                        });
                        list[_item.sort + orginListLength].value = fileList;
                      },
                    });
                    break;
                  case "DateRange":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "rangeDatePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "array",
                          required: true,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => { },
                    });
                    break;
                  default:
                    throw new Error("暂无匹配项目");
                }
              });

              if (!item) {
                form.setFieldsValue({
                  ProjectCode: await this._getProjectCode(),
                });
              } else {
                form.setFieldsValue({
                  ProjectName: item.projectName,
                  // ProjectType: item.projectType,
                  // OrganizationUnitId: item.organizationUnitId,
                  ProjectTime: [item.beginDate, item.endDate],
                  CustomerName: item.customerName,
                  ProjectCode: item.projectCode,
                  VerifiedWay: item.verifiedWay.toString(),
                  SignatureType: item.signatureType.toString(),
                });
              }

              if (res && res.results && Object.keys(res.results).length !== 0) {
                const oResult = res.results;
                let opts = {};
                list.map((it) => {
                  if (it.key) {
                    let k = it.key;
                    if (it.type === "upload") {
                      if (oResult[k].value.indexOf(",") !== -1) {
                        let arr = oResult[k].value.split(",");
                        arr.map((val, i) => {
                          let n = val.split("/");
                          it.value.push({
                            uid: `-${i + 1}`,
                            url: `${AppConsts.uploadBaseUrl}${val}`,
                            name: n[n.length - 1],
                            response: { data: { Path: val } },
                            status: "done",
                          });
                        });
                      } else {
                        if (oResult[k].value && oResult[k].value.length) {
                          let n = oResult[k].value.split("/");
                          it.value.push({
                            uid: `-1`,
                            url: `${AppConsts.uploadBaseUrl}${res.pictureUrls}`,
                            name: n[n.length - 1],
                            response: { data: { Path: res.pictureUrls } },
                            status: "done",
                          });
                        }
                      }
                    } else if (it.type === "rangeDatePicker") {
                      opts[`${k}`] =
                        oResult[k].value.indexOf("至") === -1
                          ? [oResult[k].value]
                          : oResult[k].value.split("至");
                    } else if (it.type === "datePicker") {
                      opts[`${k}`] = oResult[k].value
                        ? moment(oResult[k].value, "YYYY-MM-DD")
                        : null;
                    } else if (it.type === "checkbox") {
                      opts[`${k}`] =
                        oResult[k].value.indexOf(",") === -1
                          ? [oResult[k].value]
                          : oResult[k].value.split(",");
                    } else {
                      opts[`${k}`] = oResult[k].value;
                    }
                  }
                });
                // console.log(opts)
                this.$nextTick(() => {
                  form.setFieldsValue({ ...opts });
                });
              }

              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let fileUrl = [];
              for (let i = 0; i < list.length; i++) {
                if (list[i].type === "upload" && list[i].required) {
                  if (!list[i].value.length) {
                    this.$message.info(`请上传${list[i].label}的附件资源`);
                    break;
                  }
                }
              }

              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);

              let dynamicList = list.filter((item) => item.key);
              let _results = [];
              console.log(dynamicList);

              dynamicList.map((_item) => {
                if (
                  _item.type === "commonInput" ||
                  _item.type === "numberInput" ||
                  _item.type === "textarea" ||
                  _item.type === "radio" ||
                  _item.type === "commonSelect"
                ) {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value: values[`${_item.value}`],
                    },
                  });
                } else if (_item.type === "datePicker") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      // value: values[`${_item.value}`].format('YYYY-MM-DD')
                      value: values[`${_item.value}`],
                    },
                  });
                } else if (_item.type === "rangeDatePicker") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`][0] +
                        "至" +
                        values[`${_item.value}`][1],
                    },
                  });
                } else if (_item.type === "checkbox") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`].length === 1
                          ? values[`${_item.value}`][0]
                          : values[`${_item.value}`].join(","),
                    },
                  });
                } else if (_item.type === "upload") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        _item.value.length === 1
                          ? `${_item.value[0].response.data.Path}`
                          : _item.value
                            .map((v) => {
                              return v.response.data.Path;
                            })
                            .join(","),
                    },
                  });
                }
              });

              let submitData = {
                id: item && item.id ? item.id : null,
                projectName: values.ProjectName,
                projectCode: values.ProjectCode,
                projectType: values.ProjectType,
                beginDate: values.ProjectTime[0],
                endDate: values.ProjectTime[1],
                customerName: values.CustomerName,
                verifiedWay: Number(values.VerifiedWay),
                signatureType: Number(values.SignatureType),
                organizationUnitId: values.OrganizationUnitId,
                results: _results,
              };
              console.log("submitData:", submitData);

              try {
                if (item && item.id) {
                  let res = await this.commonService.post({
                    url: queryParams.reqUrl.update,
                    params: { ...submitData },
                  });
                } else {
                  let res = await this.commonService.post({
                    url: queryParams.reqUrl.save,
                    params: { ...submitData },
                  });
                }
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    async createReactModify1 (projectItem, id) {
      ModalHelper.create(
        CreateOrUpdate,
        { id: id, projectItem: projectItem },
        {
          isChange: true,
          width: "650px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    async _getOrgSelectList () {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Organization/GetSelectList",
          params: {},
        });
        let _arr = res.map((item) => {
          return {
            label: item.text,
            value: item.value,
          };
        });
        this.spinning = false;
        return _arr;
      } catch (error) {
        this.spinning = false;
        throw new Error(error);
      }
    },
    async _getEnumNameValueArray () {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Project/GetEnumNameValueArray",
          params: { enumType: 1 },
        });
        let _arr = res.map((item) => {
          return {
            label: item.key,
            value: item.value,
          };
        });
        this.spinning = false;
        return _arr;
      } catch (error) {
        this.spinning = false;
        throw new Error(error);
      }
    },
    async _getProjectCode () {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Project/GetProjectCode",
          params: {},
        });
        this.spinning = false;
        return res;
      } catch (error) {
        this.spinning = false;
        throw new Error(error);
      }
    },
    returnNumerInput (maxLength) {
      let str = "1";
      for (let i = 0; i < maxLength; i++) {
        str += "1";
      }
      // console.log(str)
      // console.log(Number(str) * 9)
      return Number(str) * 9;
    },
    projectSceneModal (id) {
      ModalHelper.create(
        SceneList,
        { id: id },
        { isChange: true, width: "1000px" }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
  },
  created () {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.Proxy = new ProjectServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    this.getData();
    this.getProjectType();
    this.initActionsType();
    this.getAllCutomer();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
