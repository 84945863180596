import "rxjs/add/operator/finally";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { FileDto } from "@/shared/service-proxies/model";
import moment from "moment";
export class SwaggerException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isSwaggerException = true;

  static isSwaggerException(obj: any): obj is SwaggerException {
    return obj.isSwaggerException === true;
  }
}


export class ProjectSearchDto {
  private ProjectName: string;
  private ProjectCode: string;
  private CustomerName: string;
  private ProjectJoiner: string;
  private StartDate: string;
  private EndDate: string
  private CompanyName: string;
  private ProjectId: string;
  private Sorting: string;
  private SkipCount: number;
  private MaxResultCount: number;
  private IsProjectAgreementSignedRequired: boolean;

  constructor(projectName?: string,
    projectCode?: string,
    customerName?: string,
    companyName?: string,
    ProjectJoiner?: string,
    startDate?: string,
    endDate?: string,
    sorting?: string,
    projectId?: string,
    skipCount?: number,
    maxResultCount?: number) {
    this.ProjectName = projectName;
    this.ProjectCode = projectCode;
    this.CustomerName = customerName;
    this.CompanyName = companyName;
    this.ProjectJoiner = ProjectJoiner;
    this.StartDate = startDate;
    this.EndDate = endDate
    this.ProjectId = projectId
    this.Sorting = sorting;
    this.SkipCount = skipCount;
    this.MaxResultCount = maxResultCount;
  }

  toJson(): object {
    var json = {
      ProjectName: this.ProjectName,
      ProjectCode: this.ProjectCode,
      ProjectId: this.ProjectId,
      CustomerName: this.CustomerName,
      CompanyName: this.CompanyName,
      ProjectJoiner: this.ProjectJoiner,
      StartDate: this.StartDate,
      EndDate: this.EndDate,
      Sorting: this.Sorting,
      SkipCount: this.SkipCount,
      MaxResultCount: this.MaxResultCount,
      IsProjectAgreementSignedRequired: this.IsProjectAgreementSignedRequired
    };
    return json;
  }

  formJson(json?: object): ProjectSearchDto {
    let name = json["ProjectName"];
    let code = json["ProjectCode"];
    let client = json["CustomerName"];
    let company = json["CompanyName"];
    let ProjectJoiner = json["ProjectJoiner"];
    let startDate = json["StartDate"]
    let endDate = json["EndDate"];
    let projectId = json["ProjectId"];
    let skip = json["SkipCount"];
    let count = json["MaxResultCount"];
    let sorting = json["Sorting"];
    let isProjectAgreementSignedRequired = json["IsProjectAgreementSignedRequired"];
    this.ProjectCode = code;
    this.ProjectName = name;
    this.CustomerName = client;
    this.CompanyName = company;
    this.ProjectJoiner = ProjectJoiner;
    this.StartDate = startDate;
    this.EndDate = endDate;
    this.ProjectId = projectId;
    this.SkipCount = skip;
    this.MaxResultCount = count;
    this.Sorting = sorting;
    this.IsProjectAgreementSignedRequired = isProjectAgreementSignedRequired;
    return this;
  }
}

export class ProjectDto {
  private ProjectName: string;
  private ProjectCode: string;
  // private SignCompanyId: string;
  private BeginDate: string;
  private EndDate: string;
  private Id: string;
  private ProjectType: number;
  private CustomerName: string;
  private OrganizationUnitId: number;
  private IsProjectAgreementSignedRequired: boolean;

  // private Remark: string;
  // private ContractId: string;
  // private InvoiceId: string;
  // private ProjectFinCode: string;
  // private isAuditJoin: boolean;

  constructor() {

  }

  toJson(): any {
    const json = {
      ProjectName: this.ProjectName,
      ProjectCode: this.ProjectCode,
      Id: this.Id,
      BeginDate: this.BeginDate,
      EndDate: this.EndDate,
      // SignCompanyId: this.SignCompanyId,
      ProjectType: this.ProjectType,
      IsProjectAgreementSignedRequired: this.IsProjectAgreementSignedRequired,
      // ContractId: this.ContractId,
      // Remark: this.Remark,
      // InvoiceId: this.InvoiceId,
      // ProjectFinCode: this.ProjectFinCode,
      // isAuditJoin: this.isAuditJoin,
      CustomerName: this.CustomerName,
      OrganizationUnitId: this.OrganizationUnitId
    };
    console.log('----------------------json-----------------------')
    console.log(json)
    return json;
  }

  formJson(json?: any): ProjectDto {
    console.info(json);
    let name = json["ProjectName"];
    let code = json["ProjectCode"];
    // let company = json["Company"];
    let times = json["ProjectTime"];
    let type = json["ProjectType"];
    let customerName = json["CustomerName"];
    let organizationUnitId = json["OrganizationUnitId"];
    let isProjectAgreementSignedRequired = json["IsProjectAgreementSignedRequired"];
    // let remark = json["Remark"];
    // let invoice = json["Invoice"];
    let id = json["Id"];
    this.ProjectCode = code;
    this.ProjectName = name;
    this.Id = id ? id : null;
    // this.SignCompanyId = company.key;
    // this.ContractId = json["ProjectContract"];
    this.BeginDate = times[0];
    this.EndDate = times[1];
    this.ProjectType = type;
    this.CustomerName = customerName;
    this.OrganizationUnitId = organizationUnitId;
    this.IsProjectAgreementSignedRequired = isProjectAgreementSignedRequired;
    // this.Remark = remark ? remark : null;
    // this.ProjectFinCode = json["ProjectFinCode"];
    // this.isAuditJoin = json["isAuditJoin"]
    // this.InvoiceId = invoice ? invoice.key : '00000000-0000-0000-0000-000000000000';
    return this;
  }

  setId(id: string): void {
    this.Id = id;
  }
}

export class ProjectServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.baseUrl = baseUrl
    this.instance = instance ? instance : axios.create()
  }

  GetAll(): Promise<any> {
    let url = this.baseUrl + '/api/services/app/UnnaturalPersonProject/GetAllProject';
    let options = <AxiosRequestConfig>{
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }


  GetList(dto?: ProjectSearchDto): Promise<any> {
    let url = this.baseUrl + '/api/services/app/UnnaturalPersonProject/GetRelatedPaged';
    let json = dto.toJson();
    let options = <AxiosRequestConfig>{
      data: json,
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }

  CreateOrUpdateProject(dto?: ProjectDto): Promise<any> {
    let url = ""
    if (dto.toJson().Id) {
      url = this.baseUrl + '/api/services/app/UnnaturalPersonProject/Update';
    } else {
      url = this.baseUrl + '/api/services/app/UnnaturalPersonProject/Create';
    }
    let options = <AxiosRequestConfig>{
      data: dto.toJson(),
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }

  GetProject(id?: string): Promise<any> {
    let url = this.baseUrl + '/api/services/app/UnnaturalPersonProject/GetById';
    let options = <AxiosRequestConfig>{
      params: { Id: id },
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      console.info(response)
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }

  DeleteProject(id?: string): Promise<any> {
    let url = this.baseUrl + '/api/services/app/Project/DeleteProject';
    let options = <AxiosRequestConfig>{
      params: { Id: id },
      method: "DELETE",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }

  BatchDelete(ids?: Array<string>): Promise<any> {
    let url = this.baseUrl + '/api/services/app/Project/BatchDeleteProjects';
    let options = <AxiosRequestConfig>{
      data: ids,
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }

  /* 获取项目编码 */
  getProjectCode(): Promise<any> {
    let url = this.baseUrl + '/api/services/app/Project/GetProjectCode';
    let options = <AxiosRequestConfig>{
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }

  /* 查询签约公司 */
  getCompanyName(name?: string): Promise<any> {
    let url = this.baseUrl + '/api/services/app/Customer/GetCustomerPaged';
    let options = <AxiosRequestConfig>{
      params: {
        name: name,
        maxResultCount: 1000,
        skipCount: 0
      },
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }

  /* 获取枚举值 */
  getEnumKeyValueList(type: number): Promise<any> {
    let url = this.baseUrl + '/api/services/app/Project/GetEnumNameValueArray';
    let options = <AxiosRequestConfig>{
      params: { enumType: type },
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }

  getAllContract(): Promise<any> {
    let url = this.baseUrl + '/api/services/app/Contract/GetPaged';
    let options = <AxiosRequestConfig>{
      params: { maxResultCount: 2000, skipCount: 0, Sorting: 'CreationTime' },
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }
  getOrganization(): Promise<any> {
    let url = this.baseUrl + '/api/services/app/Organization/GetSelectList';
    let options = <AxiosRequestConfig>{
      params: {},
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }
  getAllCustomerBaseInvoices(customerId: string): Promise<any> {
    let url = this.baseUrl + '/api/services/app/Customer/GetCustomerBaseInvoicePaged';
    customerId = customerId || customerId !== '' ? customerId : null;
    let options = <AxiosRequestConfig>{
      params: { maxResultCount: 1000, skipCount: 0, Sorting: '', CustomerId: customerId },
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json-patch+json",
      }
    };

    return this.instance.request(options).then((response: AxiosResponse) => {
      let status = response.status;
      if (status === 200) {
        return Promise.resolve<void>(<any>response.data);
      } else {
        const responseText = response.data;
        return throwException("An unexpected server error occurred.", status, responseText, null);
      }
    });
  }
  getToExcelFile(): Promise<FileDto> {
    let url_ = this.baseUrl + "/api/services/app/UnnaturalPersonProject/GetToExcelFile";
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processGetToExcelFile(_response);
    });
  }

  protected processGetToExcelFile(response: AxiosResponse): Promise<FileDto> {
    console.info(response)
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      result200 = FileDto.fromJS(resultData200);
      return result200;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<FileDto>(<any>null);
  }
}


function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  if (result !== null && result !== undefined) {
    throw result;
  } else {
    throw new SwaggerException(message, status, response, headers, null);
  }
}