<template>
  <a-spin :spinning="spinning">
    <section>
      <div class="modal-header">
        <div class="modal-title">
          <a-icon type="unordered-list" />采集信息列表
        </div>
      </div>
      <!-- 按钮 -->
      <a-row :gutter="8" class="btn--container">
        <a-col class="gutter-row" :span="5">
          <a-input
            v-model="searchDto.realName"
            :placeholder="l('请输入姓名')"
          />
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-input
            v-model="searchDto.idCard"
            :placeholder="l('请输入身份证号')"
          />
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-range-picker
            :placeholder="['开始日期', '结束日期']"
            @change="onChange"
          />
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-select
            style="width: 100%"
            @change="selectStatusChange"
            placeholder="请选择表单状态"
            allowClear
          >
            <a-select-option
              :value="it.value"
              v-for="(it, i) in statusList"
              :key="i"
            >
              {{ it.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button type="primary" @click="getData">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
      </a-row>
      <react-table
        :table-data="tableData"
        :actions-type="actionsType"
        :columns="columns"
        :hide-row-selection="true"
        :hide-pagination="true"
        @emitSelectChange="selectChange"
      >
      </react-table>

      <!-- <div class="modal-footer">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ l("关闭") }}
        </a-button>
        <a-button :loading="saving" :type="'primary'" @click="addTemplete">
          <a-icon type="plus" />
          {{ l("添加") }}
        </a-button>
      </div> -->
    </section>

    <a-modal
      centered
      :title="seclectModalParams.title"
      :visible="seclectModalParams.visible"
      :confirm-loading="seclectModalParams.confirmLoading"
      :cancelText="seclectModalParams.cancelText"
      :okText="seclectModalParams.okText"
      :maskClosable="seclectModalParams.maskClosable"
      :destroyOnClose="seclectModalParams.destroyOnClose"
      :width="seclectModalParams.width"
      @ok="seclectModalParams.confirm"
      @cancel="seclectModalParams.cancel"
    >
      <div>
        <a-form :form="form1" v-bind="formItemLayout">
          <a-form-item label="协议模版" has-feedback>
            <a-select
              v-decorator="[
                'projectAgreementId',
                { rules: [{ required: true, message: '请选择协议模板' }] },
              ]"
              placeholder="请选择"
            >
              <a-select-option
                v-for="(item, index) in seclectModalParams.selectList"
                :key="index"
                :value="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

    <a-modal
      centered
      :title="inputModalParams.title"
      :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading"
      :cancelText="inputModalParams.cancelText"
      :okText="inputModalParams.okText"
      :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose"
      :width="inputModalParams.width"
      @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel"
    >
      <div>
        <a-form :form="form2" v-bind="formItemLayout">
          <a-form-item label="作废原因" has-feedback>
            <a-input
              type="textarea"
              :placeholder="l('请输入')"
              v-decorator="[
                'invalidReason',
                {
                  rules: [
                    { required: true, message: this.l('ThisFieldIsRequired') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import ReactTable from "@/components/react-table";
// import ConfigList from "./config-list";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import CollectionFormResult from "./collectionformresult";

export default {
  name: "collection-form-detail",
  mixins: [AppComponentBase, ModalComponentBase],
  components: { ReactTable },
  data() {
    return {
      filterText: "",
      spinning: false,
      // 表单
      formLayout: "horizontal",
      form1: this.$form.createForm(this, { name: "coordinated" }),
      form2: this.$form.createForm(this, { name: "coordinated" }),
      isDisabled: false,

      tableKey: "",
      commonService: null,
      personnelService: null,

      tableData: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      actionsType: {},
      signStatuList: ["未签署", "单方签署", "已经签署", "归档", "已通知"],
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      seclectModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        form: {},
        id: null,
        selectList: [],
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.seclectModalParams.visible = false;
        },
      },
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        form: {},
        id: null,
        selectList: [],
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
      // 用户表格
      columns: [
        {
          title: this.l("姓名"),
          dataIndex: "realName",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: this.l("身份证号"),
          dataIndex: "idCard",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: this.l("手机号"),
          dataIndex: "phoneNumber",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "phoneNumber" },
        },
        {
          title: this.l("采集表单状态"),
          dataIndex: "isCompletedStr",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "isCompleted" },
        },
        {
          title: this.l("提交时间"),
          dataIndex: "submitTimeStr",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "submitTime" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      selectedRowKeys: [],
      selectedRows: [],
      statusList: [
        { label: "未完成", value: false },
        { label: "已完成", value: true },
      ],
      searchDto: {
        realName: "",
        idCard: "",
        startTime: "",
        endTime: "",
        isCompleted: "",
      },
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.fullData(); // 模态框必须,填充数据到data字段

    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  mounted() {
    console.log(this);
    if (this.params) {
      this.$nextTick(() => {});
    }
    this.getData();
  },
  methods: {
    getData() {
      this.initActionsType();
      this._getProjectPersonnelAgreement();
      //this._agreementTemplateGetSelectData();
    },
    async _getProjectPersonnelAgreement() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectForm/GetFormResults",
          params: {
            projectFormId: this.id,
            realName: this.searchDto.realName,
            idCard: this.searchDto.idCard,
            startTime: this.searchDto.startTime,
            endTime: this.searchDto.endTime,
            isCompleted: this.searchDto.isCompleted,
          },
        });
        this.tableData = res.map((item) => {
          return {
            ...item,
            submitTimeStr: item.submitTime
              ? moment(item.submitTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            // creationTimeStr: item.creationTime
            //   ? moment(item.creationTime).format("YYYY-MM-DD HH:mm:ss")
            //   : "-",
            isCompletedStr: item.isCompleted ? "已完成" : "未完成",
          };
        });
        this.totalItems;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _agreementTemplateGetSelectData() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url:
            "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/GetSelectData",
          params: {
            projectId: this.personnelAgreementItem.projectId,
          },
        });
        this.spinning = false;
        this.seclectModalParams.selectList = res;
      } catch (error) {
        this.spinning = false;
      }
    },
    addTemplete() {
      let _this = this;
      this.seclectModalParams.title = "选择协议模版";
      this.seclectModalParams.confirm = function () {
        _this.form1.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.seclectModalParams.confirmLoading = true;
            try {
              let res = await _this.commonService.post({
                url:
                  "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/Insert",
                params: {
                  ...values,
                  personnelId: _this.personnelAgreementItem.userId,
                },
              });
              _this.seclectModalParams.confirmLoading = false;
              _this.seclectModalParams.visible = false;
              _this._getProjectPersonnelAgreement();
            } catch (error) {
              console.log(error);
              _this.seclectModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.seclectModalParams.visible = true;
    },
    editTemplete(item) {
      console.log(item);
      let _this = this;
      this.seclectModalParams.visible = true;
      this.seclectModalParams.title = "修改协议模版";
      setTimeout(() => {
        this.form1.setFieldsValue({
          projectAgreementId: item.projectAgreementId.toUpperCase(),
        });
      }, 200);

      this.seclectModalParams.confirm = function () {
        _this.form1.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.seclectModalParams.confirmLoading = true;
            try {
              let res = await _this.commonService.post({
                url:
                  "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/UpdateProjectAgreementId",
                params: {
                  ...values,
                  id: item.id,
                },
              });
              _this.seclectModalParams.confirmLoading = false;
              _this.seclectModalParams.visible = false;
              _this._getProjectPersonnelAgreement();
            } catch (error) {
              console.log(error);
              _this.seclectModalParams.confirmLoading = false;
            }
          }
        });
      };
    },
    setInvalid(projectAgreementId) {
      let _this = this;
      this.inputModalParams.title = "填写作废原因";
      this.inputModalParams.confirm = function () {
        _this.form2.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.inputModalParams.confirmLoading = true;
            try {
              let _res = await _this.commonService.get({
                url: "/api/services/app/ProjectAgreement/SetInvalid",
                params: {
                  projectAgreementId: projectAgreementId,
                  invalidReason: values.invalidReason,
                },
              });
              _this.inputModalParams.confirmLoading = false;
              _this.inputModalParams.visible = false;
              _this._getListByProjectId();
            } catch (error) {
              _this.inputModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.inputModalParams.visible = true;
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "templete-list",
        isShow: true,
        fns: {
          dropList: [
            // {
            //   granted: true,
            //   name: this.l("Edit"),
            //   icon: "edit",
            //   fn: (data) => {
            //     // _this.createOrEdit(data.id, 'edit');
            //     _this.editTemplete(data);
            //   },
            // },
            // {
            //   granted: true,
            //   name: this.l("删除"),
            //   icon: "delete",
            //   fn: (data) => {
            //     console.log(data);
            //     _this.delPersonnelAgreement(data.id);
            //   },
            // },
            {
              granted: true,
              name: this.l("查看详情"),
              icon: "eye",
              fn: (data) => {
                console.log(data);
                if (data.id) {
                  _this._getCollectionFormResult(data.id);
                } else {
                  _this.$message.info("未完成采集,无法查看详情!");
                }
              },
            },
            // {
            //   granted: true,
            //   name: this.l("下载协议"),
            //   icon: "download",
            //   fn: (data) => {
            //     if (data.downloadUrl) {
            //       window.location.href = data.downloadUrl;
            //     } else {
            //       _this.$message.info("暂无协议");
            //     }
            //   },
            // },
          ],
        },
      };
      this.actionsType = obj;
    },
    createOrEdit(id) {
      ModalHelper.create(
        ConfigList,
        {
          id: id,
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        this._getListByProjectId();
      });
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.searchDto.realName = "";
      this.searchDto.idCard = "";
      this.getData();
    },
    delPersonnelAgreement(id) {
      let _that = this;
      this.message.confirm("是否确定删除", (res) => {
        if (res) {
          _that.delData(id);
        }
      });
    },
    async delData(id) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url:
            "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/Delete",
          params: {
            id: id,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: this.l("SuccessfullyDeleted"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    notification() {
      console.log(this.selectedRowKeys);
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "通知签署"));
        return;
      }
      this.notificationData();
    },
    async notificationData() {
      this.spinning = true;
      let projectAgreementIdArr = this.selectedRowKeys.map((item) => {
        return item.projectAgreementId;
      });
      console.log(projectAgreementIdArr);
      try {
        let res = await this.commonService.post({
          url:
            "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/SendSignNotice",
          params: {
            personnelId: this.personnelAgreementItem.userId,
            projectAgreementIds: projectAgreementIdArr,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: this.l("通知签署成功"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    selectChange(data) {
      let { selectedRowKeys, selectedRows } = data;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowKeys = selectedRows;
    },
    onChange(date, dateString) {
      console.log(date, dateString);
      this.searchDto.startTime = dateString[0];
      this.searchDto.endTime = dateString[1];
    },
    _getCollectionFormResult(id) {
      ModalHelper.create(
        CollectionFormResult,
        { id: id },
        {
          isChange: true,
          width: "600px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    selectStatusChange(value) {
      console.log(value);
      if (value == undefined) {
        this.searchDto.isCompleted = '';
      } else {
        this.searchDto.isCompleted = value;
      }
    },
  },
};
</script>

<style scoped lang="less">
// @import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
</style>
