<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="id">{{ l("Edit") }}</span>
        <span v-if="!id">{{ l("Create") }}</span>
      </div>
    </div>
    <a-form
      :form="form"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      @submit="handleSubmit"
    >
      <a-form-item label="场景名称" has-feedback>
        <a-input
          placeholder="请输入场景名称"
          v-decorator="[
            'name',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="备注" has-feedback>
        <a-textarea
          placeholder="请输入备注"
          v-decorator="[
            'remark',
            {
              rules: [{}],
            },
          ]"
        />
      </a-form-item>
    </a-form>
    <div class="btn-w">
      <a-button type="button" @click="close()">
        {{ l("Cancel") }}
      </a-button>
      <a-button type="primary" html-type="submit" @click="handleSubmit">
        {{ l("Save") }}
      </a-button>
    </div>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import { message } from "ant-design-vue";
import moment from "moment";
export default {
  mixins: [ModalComponentBase],
  name: "add-or-edit-project-scene",
  data() {
    return {
      // 获取到的数据
      entity: {},
      spinning: false,
      commonService: null,
      labelCol: {
        span: 5,
      },
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  async mounted() {
    if (this.id) {
      await this.getData();
    }
  },
  methods: {
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      var res = await this.commonService.get({
          url:"/api/services/app/UnnaturalPersonProjectScene/GetProjectSceneDetail",
          params: { id: this.id },
      });
      this.spinning = false;
      this.$nextTick(() => {
        this.form.setFieldsValue({
          ...res,
          id: this.id,
        });
      });
    },
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        console.log(err);
        console.log(values);
        if (!err) {
          this.spinning = true;
          try {
            const res = await this.commonService.post({
              url: "/api/services/app/UnnaturalPersonProjectScene/CreateOrUpdate",
              params: { ...values, id: this.id, unnaturalPersonProjectId: this.unnaturalPersonProjectId },
            });
            this.spinning = false;
            this.$notification["success"]({
              message: this.l("SavedSuccessfully"),
            });
            this.success(true);
          } catch (error) {
            this.spinning = false;
            // this.$message.error("提交失败");
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container .ant-form-item-children {
  display: block;
  margin: 10px auto;
  text-align: center;
}

.btn-w {
  width: 100%;
  display: block;
  margin: 10px auto;
  text-align: right;
}
</style>